import { useEffect, useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { ToastContainer } from "react-toastify";
import { AddProductsModal } from "../../components/AddProductModal";
import { Button } from "../../components/Button";
import { Input } from "../../components/Input";
import { ModalDeleteProducts } from "../../components/ModalDeleteProduct";
import { Table } from "../../components/Table";
import { Template } from "../../components/Template";
import { api } from "../../services/api";
import { Container } from "./styles";

export function Products() {
  const [isShowFitlers, setIsShowFilters] = useState(false);
  const [name, setName] = useState("");
  const [products, setProducts] = useState([]);
  const [isShowModalProduct, setIsShowModalProduct] = useState(false);
  const [isShowModalDeleteProduct, setisShowModalDeleteProduct] =
    useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isProduct, setIsProduct] = useState({
    id: 0,
    name: "",
    description: "",
    price: "",
    image: "",
  });

  useEffect(() => {
    api
      .get("/products", {
        headers: {
          name,
        },
      })
      .then((response) => {
        setProducts(response.data.products);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [name, products]);

  function handleModalClose() {
    setIsEdit(false);
    setIsShowModalProduct(false);
  }

  function handleisEdit(product: any) {
    setIsProduct(product);
    setIsEdit(true);
    setIsShowModalProduct(true);
  }

  function handleIsDelete(product: any) {
    setIsProduct(product);
    setisShowModalDeleteProduct(true);
  }

  function handleModalDeleteProductClose() {
    setisShowModalDeleteProduct(false);
  }

  return (
    <Template title="Produtos">
      <Container>
        <div className="buttons-content">
          <Button
            title="Filtrar"
            isPrimary
            onClick={() => {
              setIsShowFilters(!isShowFitlers);
            }}
          />
          <Button
            title="Cadastrar Novo"
            isPrimary
            onClick={() => setIsShowModalProduct(!isShowModalProduct)}
          />
        </div>
        {isShowFitlers && (
          <div className="filter-content">
            <div className="filters-inputs">
              <Input
                label="Produto"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <Button isPrimary={false} title="Limpar Filtros" />
          </div>
        )}
        <Table title="Produtos Cadastrados">
          <thead>
            <tr>
              <th>Imagem</th>
              <th>Produto</th>
              <th>Validade</th>
              <th>Preço ($)</th>
              <th>Opções</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product: any) => {
              return (
                <tr key={product.id}>
                  <td>
                    <img
                      src={`${process.env.REACT_APP_API}/public/uploads/${product.image}`}
                      alt={product.name}
                      height={50}
                      width={50}
                    />
                  </td>
                  <td>
                    <strong>{product.name}</strong>
                    <p>{product.description}</p>
                  </td>
                  <td>
                    {product.validateTime}{" "}
                    {product.validateTime > 1 ? "meses" : "mês"}
                  </td>
                  <td>R$ {parseFloat(product.price).toFixed(2)}</td>
                  <td>
                    <FaEdit
                      className="icon-edit"
                      onClick={() => handleisEdit(product)}
                    />
                    <FaTrash
                      className="icon-delete"
                      onClick={() => handleIsDelete(product)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <AddProductsModal
          productId={isEdit ? isProduct.id : null}
          product={isEdit ? isProduct : null}
          isOpen={isShowModalProduct}
          onRequestClose={handleModalClose}
        />
        <ModalDeleteProducts
          product={isProduct}
          isOpen={isShowModalDeleteProduct}
          onRequestClose={handleModalDeleteProductClose}
        />
        <ToastContainer position="top-right" />
      </Container>
    </Template>
  );
}
