import dayjs from "dayjs";
import { FormEvent, useState } from "react";
import { AiOutlineWarning } from "react-icons/ai";
import { toast, ToastContainer } from "react-toastify";
import { Button } from "../../components/Button";
import { Input } from "../../components/Input";
import { Template } from "../../components/Template";
import { api } from "../../services/api";
import { Container, Content } from "./styles";

interface IUser {
  email: string
  name: string
  phone: string
  profileId: number
  active: boolean
}

interface ICode {
  passwordCode: string
  code: string
  allotment: string
  checkout: boolean
  status: string
  active: boolean
  createdAt: Date
  updatedAt: Date
}
export function SearchCode() {
  const [code, setCode] = useState('')
  const [searchCode, setSearchCode] = useState<ICode | null>()
  const [belonged, setBelonged] = useState<IUser | null>()
  const [belongs, setBelongs] = useState<IUser | null>()
  const [itemRequest, setItemRequest] = useState<IUser | null>()

  function handleSearchCode(event: FormEvent) {
    event.preventDefault()
    handleClearStates()

    api.get('/admin/search-code', { headers: { code } }).then(response => {
      setSearchCode(response.data.searchCode)
      setBelonged(response.data.belonged)
      setBelongs(response.data.belongs)
      setItemRequest(response.data.itemRequest)
    }).catch(error => {
      switch (error.response.data.params) {
        case 'code': toast.error('Côdigo obrigatorio!'); break;
        case 'code-not': toast.error('Nenhum côdigo encontrado!'); break;
        default: toast.error('Erro ao buscar código!');
          break;
      }
    })
  }

  function handleClearStates() {
    setSearchCode(null)
    setBelonged(null)
    setBelongs(null)
  }
  return (
    <Container>
      <Template title="Buscar Código">
        <div className="filter-content">
          <form onSubmit={handleSearchCode}>
            <Input
              label="Código"
              type="text"
              name="code"
              value={code}
              onChange={e => setCode(e.target.value)}
            />
            <Button
              title="Buscar"
              type="submit"
              isPrimary
            />
          </form>
        </div>
        <Content>
          {
            !searchCode &&
            <div className="empty-alert">
              <AiOutlineWarning />
              <span>Nenhum código encontrado</span>
            </div>
          }
          {
            searchCode &&
            <div className="code-content">
              <h3>Dados do código:</h3>
              <ul>
                <li><span>Código:</span> {searchCode.passwordCode}</li>
                <li><span>Número do cartāo:</span> {searchCode.code}</li>
                <li><span>Número do lote:</span> {searchCode.allotment}</li>
                <li><span>Adicionado em:</span> {dayjs(searchCode.createdAt).format("DD/MM/YYYY")}</li>
                <li><span>Status:</span> {searchCode.status}</li>
                <li><span>Ultima atualizaçāo em:</span> {dayjs(searchCode.updatedAt).format("DD/MM/YYYY")}</li>
                <li><span>Ativo:</span> {searchCode.active ? 'Sim' : 'Nāo'}</li>
              </ul>
            </div>
          }
          {
            belongs &&
            <div className="code-content">
              <h3>Pertence:</h3>
              <ul>
                <li><span>Nome:</span> {belongs.name}</li>
                <li><span>E-mail:</span> {belongs.email}</li>
                <li><span>Telefone:</span> {belongs.phone}</li>
                <li><span>Perfil:</span> {belongs.profileId === 1 ? 'Admin' : belongs.profileId === 3 ? 'Vendedor' : 'Revendedor'}</li>
              </ul>
            </div>
          }

          {
            belonged &&
            <div className="code-content">
              <h3>Pertenceu a:</h3>
              <ul>
                <li><span>Nome:</span> {belonged.name}</li>
                <li><span>E-mail:</span> {belonged.email}</li>
                <li><span>Telefone:</span> {belonged.phone}</li>
                <li><span>Perfil:</span> {belonged.profileId === 1 ? 'Admin' : belonged.profileId === 3 ? 'Vendedor' : 'Revendedor'}</li>
              </ul>
            </div>
          }
        </Content>
      </Template>
      <ToastContainer />
    </Container>
  )
}