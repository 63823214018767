import { AllHTMLAttributes, FormEvent, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { AiOutlineClose } from 'react-icons/ai';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { api } from "../../services/api";
import { Button } from '../Button';
import { Container } from "./styles";

const fileTypes = ["JPEG", "JPG", "PNG", "PDF"];

interface IRequest {
  id: number
  createdAt: Date
  productName: string
  amount: number
  price: string
}

interface ModalSendPaymentsProps extends AllHTMLAttributes<HTMLDivElement> {
  request: IRequest | null
  isOpen: boolean
  onRequestClose: () => void
}

export const ModalSendPayments = ({ isOpen, onRequestClose, request }: ModalSendPaymentsProps) => {
  const [file, setFile] = useState<File | null>(null);

  const handleChange = (file: File) => {
    setFile(file);
  };

  function handleSendPayments(e: FormEvent) {
    e.preventDefault()

    const data = new FormData()
    data.append("file", file!)

    api.put(`/dealers/send-payment/${request?.id}`, data).then(response => {
      toast.success('Sucesso ao enviar arquivo')
      onRequestClose()
      setFile(null)
    }).catch(error => {
      toast.error('Erro ao enviar o arquivo')
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <AiOutlineClose />
      </button>
      <Container>
        <form onSubmit={handleSendPayments}>
          <h2>Enviar comprovante de Pagamento</h2>
          <FileUploader
            multiple={false}
            handleChange={handleChange}
            name="file"
            types={fileTypes}
          />
          <p>{file ? `Arquivo: ${file.name}` : "Nenhum arquivo selecionado"}</p>
          <div className="content-buttons">
            <Button type='button' title='Cancelar' isPrimary onClick={onRequestClose} />
            <Button type='submit' title='Enviar' isPrimary />
          </div>
        </form>
      </Container>
    </Modal>
  )
} 