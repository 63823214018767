import styled from "styled-components";

export const Container = styled.div`
.buttons-filter {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      background: #3C9CDB;
      padding-left: 1rem;
      padding-right: 1rem;
      height: 2.6rem;
      font-size: 1rem;
      min-width: 8rem;

      &:last-child {
        background: #58AF61;
      }
    }

    .buttons-send-codes {
      display: flex;
      gap: 1rem;

      .btn-confirm {
        background-color: var(--gray-600);
        color: var(--white);
      }

      .btn-send {
        background-color: var(--green-700);
        color: var(--white);
      }
    }
  }


  .filter-content{
    margin-top: 0.5rem;
    padding: 1rem;
    background: var(--white);
    border-radius: 1rem;

    .inputs-content {
      display: flex;
      gap: 1rem;
    }

    .buttons-content {
      display: flex;
      gap: 1rem;
      width: 25%;
      

      button {
        height: 2.5rem;
        width: 100%;
        background: var(--green-700);
        font-size: 1rem;

        &:last-child {
          background: var(--gray-600);
        }
      }
    }
  }

  .btn-cancel {
      border: none;
      background: var(--fire);
      color: var(--white);
      padding: 0.25rem;
      border-radius: 5px;
      transition: all 0.2s;

      &:hover {
        transform: scale(1.1);

        &::before {
          content: 'Pedir cancelamento';
          position: absolute;
          background: var(--gray-600);
          font-size: 0.5rem;
          top: -1.5rem;
          left: 0;
          padding: 0.25rem;
          border-radius: 5px;
          z-index: 1;
        }
      }
    }

    .estoque {
      color: #3C9CDB;
      text-transform: capitalize;
    }

    .pendente {
      color: #EB5733;
      text-transform: capitalize;
    }

    .cancelado {
      color: var(--fire);
      text-transform: capitalize;
    }

    .vendido {
      color: var(--green-700);
      text-transform: capitalize;
    }
`