import styled from "styled-components";

export const Container = styled.div`
  background: var(--white);
  padding: 2rem;
  border-radius: 0.5rem;
  margin-top: 1rem;
  width: 100%;
`

export const Filter = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  gap: 1rem;

  label {
    font-size: 1rem;
  }

  select {
    padding: 0.5rem;
    border-radius: 0.25rem;
    border: 1px solid var(--gray-300);
  }
`



