import { FormEvent, useState } from "react";
import {
  HiOutlineKey,
  HiOutlineLockClosed,
  HiOutlineMail,
  HiOutlinePhone,
  HiOutlineUser,
} from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Button } from "../../components/Button";
import { Input } from "../../components/Input";
import { api } from "../../services/api";
import { Container, Content } from "./styles";

import Logo from "../../assets/images/logo-btv.svg";

export function SignUp() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [authKey, setAuthKey] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);
  const navigate = useNavigate();

  async function handleSignup(event: FormEvent) {
    event.preventDefault();
    setBtnDisable(true);
    const data = { name, email, phone, authKey, password, confirmPassword };

    // const response = await api.post('/user/signup', data)

    // setBtnDisable(false)
    api
      .post("/user/signup", data)
      .then((response) => {
        toast.success("Usuario criado com sucesso faça login para continuar");
        navigate("/");
        setBtnDisable(false);
      })
      .catch((error) => {
        switch (error.response.data.param) {
          case "name":
            toast.error("Favor inserir um Nome!");
            break;
          case "email":
            toast.error("Favor inserir um E-mail valido!");
            break;
          case "phone":
            toast.error("Favor inserir um Telefone!");
            break;
          case "authKey":
            toast.error("Favor inserir uma Chave de authenticação valida!");
            break;
          case "password":
            toast.error("Favor inserir uma Senha!");
            break;
          case "confirmPassword":
            toast.error("Favor confirmar sua senha!");
            break;
          case "invalid-email":
            toast.error("E-mail inserido invalido!");
            break;
          case "email-used":
            toast.error("E-mail inserido já esta sendo utilizado!");
            break;
          case "password-different":
            toast.error("A confirmação da senha nao confere com a senha!");
            break;
          case "access-key-not":
            toast.error("A chave de acesso e invalida!");
            break;
          case "access-key-used":
            toast.error("A chave de acesso já foi utilizada!");
            break;
          case "expired-token":
            toast.error("A chave de acesso expirada!");
            break;

          default:
            toast.error("Erro inesperado tente novamente mais tarde!");
            break;
        }
        setBtnDisable(false);
      });
  }

  return (
    <Container>
      <Content>
        <form action="signup" onSubmit={handleSignup}>
          <h2>Registre-se</h2>

          <Input
            name="name"
            label="Nome"
            icon={HiOutlineUser}
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            name="email"
            label="E-mail"
            icon={HiOutlineMail}
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            name="phone"
            label="Telefone"
            icon={HiOutlinePhone}
            type="text"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <Input
            name="auth-key"
            label="Chave de autorização"
            icon={HiOutlineKey}
            type="text"
            value={authKey}
            onChange={(e) => setAuthKey(e.target.value)}
          />
          <Input
            name="password"
            label="Senha"
            icon={HiOutlineLockClosed}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Input
            name="confirmation-password"
            label="Confirmação de Senha"
            icon={HiOutlineLockClosed}
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <Button
            type="submit"
            title="Salvar"
            isPrimary
            disabled={btnDisable}
            loading={btnDisable}
          />
          <span>
            Já tem uma conta?
            <Link to="/">Fazer login</Link>
          </span>
        </form>
        <section>
          <img src="/images/logo-btv.svg" alt="" />
          <h1>Faça parte do grupo que mais cresce em todo o mundo.</h1>
          <p>
            Fale com seu fornecedor e garanta seu acesso em nossa plataforma
          </p>
          <img src={Logo} alt="BTV APP" />
        </section>
      </Content>
      <ToastContainer position="top-right" />
    </Container>
  );
}
