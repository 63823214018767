import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { FiDownload } from "react-icons/fi";
import { HiOutlineSearch } from "react-icons/hi";
import { Button } from "../../components/Button";
import { Input } from "../../components/Input";
import { Table } from "../../components/Table";
import { Template } from "../../components/Template";
import { useAuth } from "../../hooks/use-hook";
import { api } from "../../services/api";
import { Container } from "./styles";
import * as xlsx from "xlsx";
import { ModalCustomerSendDetail } from "../../components/ModalCustomerSendDetails";

export function CustomerCodesSend() {
  const [sends, setSents] = useState([]);
  const [isFilter, setIsFilter] = useState(false);
  const [email, setEmail] = useState("");
  const [isModalDetail, setIsModalDetail] = useState(false);
  const [sendId, setSendId] = useState(0);

  const { user } = useAuth();

  useEffect(() => {
    handleGetCodesByDealer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleGetCodesByDealer() {
    api
      .get(`/customer/list-sends/${user?.id}`, { headers: { email } })
      .then((response) => {
        setSents(response.data.sends);
      });
  }

  async function handleClearFilters() {
    setEmail("");
  }

  function handleOpenModalDetails(id: number) {
    setSendId(id);
    setIsModalDetail(true);
  }

  function handleExportData(data: any) {
    const items = JSON.parse(data);

    const table = items.map((row: any) => {
      row.Validade = row.product;
      row.Codigo = row.passwordCode;

      delete row.product;
      delete row.passwordCode;

      return row;
    });

    const workSheet = xlsx.utils.json_to_sheet(table);
    const workBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(workBook, workSheet, "codigos");
    //Buffer
    xlsx.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    xlsx.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    xlsx.writeFile(workBook, "Codigos.xlsx");
  }

  return (
    <Container>
      <Template title="Pedidos Enviados">
        <div className="buttons-filter">
          <Button
            title="Filtrar"
            isPrimary
            onClick={() => setIsFilter(!isFilter)}
          />
        </div>
        {isFilter && (
          <div className="filter-content">
            <div className="inputs-content">
              <Input
                label="E-mail"
                name="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="buttons-content">
              <Button
                type="button"
                isPrimary
                title="Buscar"
                onClick={() => handleGetCodesByDealer()}
              />
              <Button
                type="button"
                isPrimary
                title="Limpar Filtros"
                onClick={() => handleClearFilters()}
              />
            </div>
          </div>
        )}
        <Table title="Envios">
          <thead>
            <tr>
              <th>Data</th>
              <th>E-mail</th>
              <th>Produto</th>
              <th>Quantidade</th>
              <th>Opções</th>
            </tr>
          </thead>
          <tbody>
            {sends.map((item: any) => {
              return (
                <tr key={item.id}>
                  <td>{dayjs(item.createdAt).format("DD/MM/YYYY HH:mm")}</td>
                  <td>{item.emailClient}</td>
                  <td>{item.product?.name ?? "-"}</td>
                  <td>{item.amount ?? "-"}</td>
                  <td className="buttons-content">
                    <button
                      type="button"
                      className="send-details"
                      onClick={() => handleOpenModalDetails(item.id)}
                    >
                      <HiOutlineSearch />
                    </button>
                    <button
                      type="button"
                      className="send-xlsx"
                      onClick={() => handleExportData(item.data)}
                    >
                      <FiDownload />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Template>
      {sendId !== 0 && (
        <ModalCustomerSendDetail
          isOpen={isModalDetail}
          onRequestClose={() => setIsModalDetail(false)}
          sendCodeId={sendId}
        />
      )}
    </Container>
  );
}
