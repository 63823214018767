import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Button } from "../../components/Button";
import { Input } from "../../components/Input";
import { ModalDeleteCancellation } from "../../components/ModalDeleteCancellation";
import { Select } from "../../components/Select";
import { Table } from "../../components/Table";
import { Template } from "../../components/Template";
import { useAuth } from "../../hooks/use-hook";
import { api } from "../../services/api";
import { Container } from "./styles";
import Swal from "sweetalert2";
import { ModalCustomerRequestCancellation } from "../../components/ModalCustomerRequestCancellation";

interface IProduct {
  name: string;
}

interface IAuthCodes {
  id: number;
  code: string;
  belongsId: number;
  product?: IProduct;
}

export function CustomerCodes() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [codes, setCodes] = useState([]);
  const [products, setProducts] = useState([]);
  const [status, setStatus] = useState("estoque");
  const [code, setCode] = useState("");
  const [product, setProduct] = useState("");
  const [isFilter, setIsFilter] = useState(true);
  const [authCodes, setAuthCodes] = useState<string[]>([]);
  const [isShowRequestCancellatioModal, setIsShowRequestCancellationModal] =
    useState(false);
  const [
    isShowDeleteCancellationRequestModal,
    setIsDeleteCancellationRequestModal,
  ] = useState(false);
  const [authCode, setAuthCode] = useState<IAuthCodes | null>(null);

  const options = products.map((item: any) => {
    return { label: item.name, value: item.id };
  });

  function handleShowDelete(authCode: IAuthCodes) {
    setIsDeleteCancellationRequestModal(true);
    setAuthCode(authCode);
  }

  function handleShowModalRequestClose(authCode: IAuthCodes) {
    setAuthCode(authCode);
    setIsShowRequestCancellationModal(true);
  }

  useEffect(() => {
    api
      .get("/products/")
      .then((response) => {
        setProducts(response.data.products);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  async function confirmSalesCodes() {
    Swal.fire({
      title: "Confirmar vendas destes códigos?",
      text: "Confira seus codigos selecionados",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#049938",
      cancelButtonColor: "#c33a3a",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .post("/customer/confirm-sales", { authCodes })
          .then((response) => {
            Swal.fire(
              "Vendas Confirmadas!",
              "Seus códigos ja baixaram do seu inventario.",
              "success"
            );
            handleGetCodesByCustomer();
          })
          .catch(() => {
            Swal.fire(
              "Erro ao processar a operação!",
              "Confira os dados e tente novamente.",
              "error"
            );
          });
      }
    });
  }

  function multSelectCodes(id: string) {
    const list = [...authCodes];

    const isExist = list.find((item) => item === id);

    if (!isExist) {
      list.push(id);
    }

    if (isExist) {
      const index = list.indexOf(id);

      list.splice(index, 1);
    }

    setAuthCodes([...list]);
  }

  async function handleGetCodesByCustomer() {
    api
      .get(`/customer/codes/${user?.id}`, {
        headers: {
          productId: product,
          status,
          code,
        },
      })
      .then((response) => {
        setCodes(response.data.codes);
      })
      .catch((error) => {
        toast.error("Erro ao buscar dados");
      });
  }

  useEffect(() => {
    handleGetCodesByCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowRequestCancellatioModal, isShowDeleteCancellationRequestModal]);

  function handleClearFilters() {
    setProduct("");
    setCode("");
    setStatus("");
  }

  return (
    <Container>
      <Template title="Códigos">
        <div className="buttons-filter">
          <Button
            title="Filtrar"
            isPrimary
            onClick={() => setIsFilter(!isFilter)}
          />
          <div className="buttons-send-codes">
            {authCodes.length > 0 && (
              <Button
                className="btn-confirm"
                title="Confirmar Venda"
                isPrimary
                onClick={() => confirmSalesCodes()}
              />
            )}
            <Button
              className="btn-send"
              title="Enviar Código"
              isPrimary
              onClick={() => navigate("/customer/send-codes")}
            />
          </div>
        </div>
        {isFilter && (
          <div className="filter-content">
            <div className="inputs-content">
              <Select
                label="Status"
                name="status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                options={[
                  { label: "Em estoque", value: "estoque" },
                  { label: "Vendidos", value: "vendido" },
                  { label: "Pendente", value: "pendente" },
                  { label: "Cancelados", value: "cancelado" },
                ]}
              />
              <Select
                label="Produto"
                name="product"
                value={product}
                onChange={(e) => setProduct(e.target.value)}
                options={options}
              />
              <Input
                label="Código"
                name="code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </div>
            <div className="buttons-content">
              <Button
                type="button"
                isPrimary
                title="Buscar"
                onClick={() => handleGetCodesByCustomer()}
              />
              <Button
                type="button"
                isPrimary
                title="Limpar Filtros"
                onClick={() => handleClearFilters()}
              />
            </div>
          </div>
        )}

        <Table title="Meus Códigos">
          <thead>
            <tr>
              <th></th>
              <th>Código</th>
              <th>Lote</th>
              <th>Produto</th>
              <th>Status</th>
              <th>Opções</th>
            </tr>
          </thead>
          <tbody>
            {codes.map((item: any) => {
              return (
                <tr key={item.id}>
                  <td>
                    {item.status !== "vendido" && (
                      <input
                        className=""
                        type="checkbox"
                        name={item.passwordCode}
                        value={item.id}
                        onChange={(e) => multSelectCodes(e.target.value)}
                      />
                    )}
                  </td>
                  <td>{item.passwordCode}</td>
                  <td>{item.allotment}</td>
                  <td>{item.product.name}</td>
                  <td className={item.status}>{item.status}</td>
                  {item.status === "vendido" && (
                    <td>
                      <button
                        type="button"
                        className="btn-cancel"
                        onClick={() => handleShowModalRequestClose(item)}
                      >
                        <AiOutlineClose />
                      </button>
                    </td>
                  )}
                  {item.status === "pendente" && (
                    <td>
                      <FaTrash
                        className="icon-delete"
                        onClick={() => handleShowDelete(item)}
                      />
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Template>
      <ModalCustomerRequestCancellation
        isOpen={isShowRequestCancellatioModal}
        onRequestClose={() => setIsShowRequestCancellationModal(false)}
        authCode={authCode}
      />
      <ModalDeleteCancellation
        isOpen={isShowDeleteCancellationRequestModal}
        onRequestClose={() => setIsDeleteCancellationRequestModal(false)}
        authCode={authCode}
      />
      <ToastContainer position="top-right" />
    </Container>
  );
}
