import styled from "styled-components";

export const Container = styled.div`
  .send-codes {
    background-color: var(--white);
    width: 50%;
    padding: 2rem;
    border-radius: 1rem;

    h2 {
      font-size: 1.2rem;
      margin-bottom: 2rem;
    }

    form {
      display:  flex;
      flex-direction: column;
      gap: 1rem;

      .product-select {
        display: flex;
        gap: 1rem;
      }

      .buttons-send-codes {
        display: flex;
        gap: 1rem;

        button {
          width: 50%;
          background: var(--gray-600);
          font-size: 1rem;
        }

        .btn-send {
          background: var(--green-700);
          color: var(--white);
        }
      }
    }
  }
`

