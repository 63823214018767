import dayjs from "dayjs";
import { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import { SalesEvolutionSales } from "../../components/Charts/sales-evolution";
import { TopSalesChart } from "../../components/Charts/top-sales";
import { TransactionsByYear } from "../../components/Charts/transactions-by-year";
import { Select } from "../../components/Select";
import { Template } from "../../components/Template";
import { api } from "../../services/api";
import { translate } from "../../utils/translate";
import { Chip, Container, Filter, TopList } from "./styles";

interface IOptionsProps {
  label: string;
  value: string;
}

interface ITopListProps {
  name: string;
  total: number;
  profile: string;
}
export function Home() {
  const [period, setPeriod] = useState("");
  const [profile, setProfile] = useState("");
  const [user, setUser] = useState("");
  const [users, setUsers] = useState<IOptionsProps[]>([
    { label: "", value: "" },
  ]);
  const [profiles, setProfiles] = useState<IOptionsProps[]>([
    { label: "", value: "" },
  ]);
  const [monthlyTotal, setMonthlyTotal] = useState(0);
  const [yearlyTotal, setYearlyTotal] = useState(0);
  const [salesMonthlyTotal, setSalesMonthlyTotal] = useState(0);
  const [salesYearlyTotal, setSalesYearlyTotal] = useState(0);
  const [cancellationTotal, setCancellationTotal] = useState(0);
  const [topList, setTopList] = useState<ITopListProps[]>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    api
      .get(`/dashboard/list-users`, {
        headers: {
          profileId: profile,
        },
      })
      .then((response) => {
        const data = response.data;

        const transform = data.map((item: any) => {
          return { label: item.name, value: item.id };
        });

        setUsers([{ value: "", label: "Todos" }, ...transform]);
      });

    api.get(`/dashboard/list-profiles`).then((response) => {
      const data = response.data;

      const transform = data.map((item: any) => {
        return { label: translate(item.description), value: item.id };
      });

      const filters = transform.filter((item: any) => item.label !== "Admin" && item.label !== "Financeiro");

      setProfiles([{ value: "", label: "Todos" }, ...filters]);
    });
    setLoading(false);
  }, [profile]);

  useEffect(() => {
    setLoading(true);
    // api
    //   .post(`/dashboard/show-stats`, {
    //     profileId: parseInt(profile),
    //     userId: parseInt(user),
    //     period,
    //   })
    //   .then((response) => {
    //     const data = response.data;

    //     setMonthlyTotal(data.monthlyTotal);
    //     setYearlyTotal(data.yearlyTotal);
    //     setSalesMonthlyTotal(data.salesMonthlyTotal);
    //     setSalesYearlyTotal(data.salesYearlyTotal);
    //     setCancellationTotal(data.cancellationTotal);

    //     setLoading(false);
    //   });

    api
      .post("/dashboard/list-top-sales", {
        profileId: parseInt(profile),
        userId: parseInt(user),
        period,
      })
      .then((response) => {
        const data = response.data;

        const result = data.sort((a: any, b: any) => {
          return b.total - a.total;
        });
        setTopList(result);
        setLoading(false);
      });
  }, [profile, user, period]);

  const chips = [
    { value: "weekly", label: "Semanal" },
    { value: "monthly", label: "Mensal" },
    { value: "yearly", label: "Anual" },
  ];

  return (
    <Container>
      <Template title="Dashboard">
        {loading && (
          <ContentLoader height="90%" width="100%" viewBox="0 0 100% 100%">
            <rect x="0" y="0" rx="0" ry="0" width="100%" height="15%" />

            <rect x="0" y="16%" rx="0" ry="0" width="16%" height="15%" />
            <rect x="17%" y="16%" rx="0" ry="0" width="16%" height="15%" />
            <rect x="34%" y="16%" rx="0" ry="0" width="16%" height="15%" />
            <rect x="51%" y="16%" rx="0" ry="0" width="16%" height="15%" />
            <rect x="68%" y="16%" rx="0" ry="0" width="16%" height="15%" />
            <rect x="85%" y="16%" rx="0" ry="0" width="16%" height="15%" />

            <rect x="0" y="35%" rx="0" ry="0" width="50%" height="50%" />
            <rect x="52%" y="35%" rx="0" ry="0" width="50%" height="50%" />

            <rect x="0" y="90%" rx="0" ry="0" width="100%" height="20%" />
          </ContentLoader>
        )}
        {!loading && (
          <>
            <Filter>
              <div className="selects">
                <Select
                  label="Perfil"
                  name="profile"
                  options={profiles}
                  value={profile}
                  onChange={(e) => setProfile(e.target.value)}
                />
                <Select
                  label="Usúario"
                  name="users"
                  value={user}
                  onChange={(e) => setUser(e.target.value)}
                  options={users}
                />
              </div>
              <div className="periods">
                {chips.map((item) => {
                  return (
                    <Chip
                      key={item.value}
                      id={item.value}
                      onClick={() => setPeriod(item.value)}
                      className={period === item.value ? "active" : ""}
                    >
                      <strong>{item.label}</strong>
                    </Chip>
                  );
                })}
              </div>
            </Filter>
            {/* <Stats>
              <div className="card one">
                <MdOutlineProductionQuantityLimits />
                <div className="score">
                  <span>Total Mensais</span>
                  <h2>{monthlyTotal + salesMonthlyTotal}</h2>
                </div>
              </div>
              <div className="card one">
                <MdPointOfSale />
                <div className="score">
                  <span>Mensais Vendidos</span>
                  <h2>{salesMonthlyTotal}</h2>
                </div>
              </div>
              <div className="card four">
                <MdOutlineProductionQuantityLimits />
                <div className="score">
                  <span>Restantes Mensais</span>
                  <h2>{monthlyTotal}</h2>
                </div>
              </div>
              <div className="card two">
                <MdOutlineProductionQuantityLimits />
                <div className="score">
                  <span>Total Anuais</span>
                  <h2>{yearlyTotal + salesYearlyTotal}</h2>
                </div>
              </div>
              <div className="card two">
                <MdPointOfSale />
                <div className="score">
                  <span>Anuais Vendidos</span>
                  <h2>{salesYearlyTotal}</h2>
                </div>
              </div>
              <div className="card three">
                <MdOutlineProductionQuantityLimits />
                <div className="score">
                  <span>Restantes Anuais</span>
                  <h2>{yearlyTotal}</h2>
                </div>
              </div>
            </Stats> */}
            <div className="content">
              <SalesEvolutionSales
                period={period}
                userId={user}
                profileId={profile}
              />
              <TopList>
                <h4>Top Vendas</h4>
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Nome</th>
                      <th>Perfil</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {topList &&
                      topList.map((item, index) => {
                        return (
                          <tr key={item.name}>
                            <td>{index + 1}°</td>
                            <td>{item.name}</td>
                            <td>{translate(item.profile)}</td>
                            <td>{item.total}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </TopList>
            </div>
            <TopSalesChart data={topList ?? []} />
            <TransactionsByYear initialYear={dayjs().format("YYYY")} />
          </>
        )}
      </Template>
    </Container>
  );
}
