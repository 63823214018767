import { FormEvent, useEffect, useState } from "react"
import { AiOutlineClose } from 'react-icons/ai'
import Modal from 'react-modal'
import { toast } from 'react-toastify'
import { api } from "../../services/api"
import { Button } from "../Button"
import { Input } from "../Input"
import { Select } from "../Select"
import { ToggleSwitch } from "../ToggleSwitch"
import { Container } from "./styles"

interface EditProduct {
  name: string
  description: string
  price: string
  validateTime?: string
  image: string
}

interface AddProductsModalProps {
  isOpen: boolean
  product?: EditProduct | null
  productId?: number | null
  onRequestClose: () => void
}

export const AddProductsModal = ({ isOpen, onRequestClose, product, productId }: AddProductsModalProps) => {
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [price, setPrice] = useState('')
  const [validateTime, setValidateTime] = useState('')
  const [image, setImage] = useState('')
  const [file, setFile] = useState<any | null>(null)
  const [btnDisable, setBtnDisable] = useState(false)
  const [isEditImage, setIsEditImage] = useState(false)

  function clearForm() {
    setFile(null)
    setName('')
    setDescription('')
    setPrice('')
    setValidateTime('')
  }
  useEffect(() => {
    setBtnDisable(false)
    if (productId && product) {
      setName(product.name)
      setDescription(product.description)
      setValidateTime(product?.validateTime!)
      setPrice(product.price)
      setImage(product.image)
      setIsEditImage(false)
    } else {
      clearForm()
    }
  }, [product, productId])

  function handleAddProduct() {
    setBtnDisable(true)
    const data = new FormData()
    data.append("file", file)
    data.append("name", name)
    data.append("description", description)
    data.append("price", price)
    data.append("validateTime", validateTime)

    api.post('/products/create', data).then((response) => {
      toast.success('Produto cadastrado com sucesso!')
      setBtnDisable(false)
      clearForm()
      onRequestClose()
    }).catch((error) => {
      const statusCode = error.response.status
      const param = error.response.data.param
      if (statusCode === 400) {
        if (param === 'name') {
          toast.error('O campo nome e obrigratorio!')
        }
        if (param === 'description') {
          toast.error('O campo descrição e obrigratorio!')
        }
        if (param === 'price') {
          toast.error('O campo preço e obrigratorio!')
        }
      }
      if (statusCode === 500) {
        toast.error('O campo imagem e obrigratorio!')
      }
      if (statusCode === 409) {
        toast.error('Já existe um produto com esse nome!')
      }
      setBtnDisable(false)
    })
  }

  function handleEdit() {
    setBtnDisable(true)

    const data = { name, description, price, validateTime }
    api.put(`/products/${productId}`, data).then((response) => {
      toast.success('Usuário editado com sucesso!')
      setBtnDisable(false)
      onRequestClose()
      clearForm()
    }).catch((error) => {
      const statusCode = error.response.status
      const param = error.response.data.param
      if (statusCode === 400) {
        if (param === 'name') {
          toast.error('O campo nome e obrigratorio!')
        }
        if (param === 'description') {
          toast.error('O campo descrição e obrigratorio!')
        }
        if (param === 'price') {
          toast.error('O campo preço e obrigratorio!')
        }
      }
      if (statusCode === 500) {
        toast.error('O campo imagem e obrigratorio!')
      }
      if (statusCode === 409) {
        toast.error('Já existe um produto com esse nome!')
      }
      setBtnDisable(false)
    })

    if (isEditImage) {
      const data = new FormData()
      data.append("file", file)
      api.put(`/products/image/${productId}`, data).then(response => {
        toast.success('Sucesso em Atualizar a imagem')
      }).catch(error => {
        toast.error('Erro ao atuaizar a imagem')
      })
    }
  }

  function handleExecute(event: FormEvent) {
    event.preventDefault()
    if (productId) {
      handleEdit()
    } else {
      handleAddProduct()
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close">
        <AiOutlineClose />
      </button>
      <Container onSubmit={handleExecute}>
        <h2>Cadastrar Produto</h2>
        <Input
          name='name'
          label='Nome'
          type='text'
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <Input
          name='description'
          label='Descrição'
          type='text'
          value={description}
          onChange={e => setDescription(e.target.value)}
        />
        <Select
          label="Tempo de Validade"
          name="validatetime"
          value={validateTime}
          onChange={e => setValidateTime(e.target.value)}
          options={[
            { label: "1 Mês", value: 1 },
            { label: "2 Mês", value: 2 },
            { label: "3 Mês", value: 3 },
            { label: "4 Mês", value: 4 },
            { label: "5 Mês", value: 5 },
            { label: "6 Mês", value: 6 },
            { label: "7 Mês", value: 7 },
            { label: "8 Mês", value: 8 },
            { label: "9 Mês", value: 9 },
            { label: "10 Mês", value: 10 },
            { label: "11 Mês", value: 11 },
            { label: "1 Ano", value: 12 },
          ]}
        />

        <Input
          name='price'
          label='Preço'
          type='number'
          step="0.01"
          min="0"
          value={price}
          onChange={e => setPrice(e.target.value)}
        />
        {
          image &&
          !isEditImage &&
          <div className="img-edit" >
            <img src={`${process.env.REACT_APP_API}/public/uploads/${image}`} alt={name} />
          </div>
        }
        {
          productId && (
            <ToggleSwitch title="Trocar imagem?" onCLick={() => setIsEditImage(!isEditImage)} value={isEditImage} />
          )

        }
        {
          !productId && (
            <Input
              name="image"
              label="Imagem"
              type="file"
              accept="image/png, image/jpeg"
              onChange={e => setFile(e.target.files![0])}
            />
          )
        }
        {
          productId &&
          isEditImage &&
          <Input
            name="image"
            label="Imagem"
            type="file"
            accept="image/png, image/jpeg"
            onChange={e => setFile(e.target.files![0])}
          />

        }

        <Button type="submit" title="Salvar" isPrimary disabled={btnDisable} loading={btnDisable} />
      </Container>
    </Modal>
  )
}