import { FormEvent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Button } from "../../components/Button";
import { Input } from "../../components/Input";
import { api } from "../../services/api";
import { Container } from "./styles";

export function Forgot() {
  const [email, setEmail] = useState('')
  const navigate = useNavigate()

  function handleForgotPassword(event: FormEvent) {
    event.preventDefault()

    api.post('/user/forgot-password', { email }).then(response => {
      toast.success('Acesse seu e-mail para continuar com a recuperação de senha!')
      navigate('/')
      setEmail('')
    }).catch(error => {
      toast.error('Confira seu e-mail e tente novamente!')
    })
  }

  return (
    <Container>
      <div className="forgot-content">
        <h2>Recuperar senha</h2>
        <form onSubmit={handleForgotPassword}>
          <Input label="E-mail" type="email" name="email"
            value={email} onChange={e => setEmail(e.target.value)} />
          <Button title="Enviar" isPrimary type="submit" />
          <Link to="/" >Voltar</Link>
        </form>
      </div>
      <ToastContainer position="top-right" />
    </Container>
  )
}