import React, { useState } from 'react';
import { IconBaseProps } from 'react-icons';
import { AiOutlineUser } from 'react-icons/ai';
import { MdLogout } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/use-hook';
import { Container } from "./styles";

interface DropdownMenuProps {
  icon: React.ComponentType<IconBaseProps>;
}

export const DropdownMenu = ({
  icon: Icon
}: DropdownMenuProps) => {
  const { signOut } = useAuth()
  const [isOpen, setIsOpen] = useState(false)

  async function handleLogOut() {
    signOut()
  }

  return (
    <Container>
      {<Icon onClick={() => setIsOpen(!isOpen)} />}
      {
        isOpen &&
        <div className="dropdown" onClick={() => setIsOpen(false)}>
          <div className='content'>
            <Link to="/profile">
              <AiOutlineUser />
              Meus Dados
            </Link>
            <span onClick={handleLogOut}>
              <MdLogout />
              Sair
            </span>
          </div >
        </div>
      }
    </Container >
  )
}