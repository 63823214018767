import { AllHTMLAttributes, FunctionComponent, useState } from "react"
import { Header } from "../Header"
import { Sidebar } from "../Sidebar"
import { Container } from "./styles"

interface TemplateProps extends AllHTMLAttributes<HTMLDivElement> {
  title: string
}

export const Template: FunctionComponent<TemplateProps> = ({
  title,
  children
}) => {

  const [toggle, setToggle] = useState(false)

  const onToggle = () => {
    setToggle(!toggle)
  }

  return (
    <Container>
      <Sidebar toggle={toggle} />
      <Header onToggle={onToggle} />
      <main>
        <header>
          <span>{title}</span>
        </header>
        {children}
      </main>
    </Container>
  )
}