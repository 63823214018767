import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  
  label {
    display: block;
    font-size: 1rem;
    font-weight: 400;
    color: var(--gray-600);
    margin-bottom: 0.5rem;
  }

  select {
      width: 100%; 
      outline: 0;
      border: none;
      padding: 5px;
      font-size: 1.2rem;
      color: var(--gray-800);
      background: var(--gray-50);
      border: 1px solid none;
      height: 2.6rem;
      border-radius: 0.25rem;
    }
`