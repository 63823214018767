import { useContext } from 'react'
import { FiMenu, FiSettings } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../contexts/AuthContext'
import { DropdownMenu } from "../DropdownMenu"
import { Container } from "./styles"

import Logo from '../../assets/images/logo-btv.svg'

interface HeaderProps {
  onToggle: () => void
  isProfile?: boolean
}

export const Header = ({
  onToggle,
  isProfile
}: HeaderProps) => {
  const { user } = useContext(AuthContext)
  const navigate = useNavigate()

  return (
    <Container>
      {
        !isProfile &&
        <FiMenu className="side-toggle" onClick={() => onToggle()} />
      }
      {
        isProfile &&
        <img src={Logo} alt="BTV APP" onClick={() => navigate('/')} className="logo" />
      }
      <div className="user-content">
        <span>{user?.name!}</span>
        <DropdownMenu icon={FiSettings} />
      </div>
    </Container>
  )
}