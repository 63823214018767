import styled from "styled-components";

export const Container = styled.div`
  .filter-content {
    margin-top: 1rem;
    background: var(--white);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;

    #input {
      width: calc(100% / 3);
      font-size: 1rem;
    }

    button {
      width: calc(100% / 6);
      font-size: 1rem;
    }
  }
`

export const Content = styled.div`
  width: 100%;

  .empty-alert {
    width: 100%;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    gap: 1rem;
    flex-direction: column;
    align-items: center;
    
    svg {
      font-size: 4rem;
      color: var(--gray-600);
    }

    span {
      color: var(--gray-600);
      font-size: 1.2rem;
      text-transform: uppercase;
    }
  }

  .code-content {
    margin-top: 1rem;
    width: 100%;
    background-color: var(--white);
    padding: 2rem;

    h3 {
      margin-bottom: 2rem;    
    }

    ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      li {
        color: var(--gray-600);

        span {
          font-size: 1rem;
          color: #000;
        }
      }
    }
  }
`
