import { useContext, useEffect, useState } from "react"
import { AuthContext } from "../../contexts/AuthContext"
import { api } from "../../services/api"
import { Container } from "./styles"

export const BadRequests = () => {
  const [amountRequestCodes, setAmountRequestCodes] = useState(0)
  const { user } = useContext(AuthContext);

  useEffect(() => {
    api.get(`/requests/count-peddings/${user?.id}`).then(response => {
      setAmountRequestCodes(response.data.totalPeddings)
    }).catch(error => {
      console.log(error)
    })
  }, [amountRequestCodes, user])

  if (!amountRequestCodes) {
    return null
  }

  return (
    <Container>
      <span>{amountRequestCodes}</span>
    </Container>
  )
}