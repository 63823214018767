import { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Button } from "../../components/Button";
import { Input } from "../../components/Input";
import { Select } from "../../components/Select";
import { Template } from "../../components/Template";
import { useAuth } from "../../hooks/use-hook";
import { api } from "../../services/api";
import { Container } from "./styles";
import * as xlsx from "xlsx";

export function CustomerSendCodes() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [email, setEmail] = useState("");
  const [product, setProduct] = useState("");
  const [amount, setAmount] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);

  async function handleSendCodesToEmail(e: FormEvent) {
    e.preventDefault();
    setBtnDisable(true);

    const data = {
      customerId: user?.id,
      email,
      productId: product,
      amount: parseInt(amount),
    };

    api
      .post("/customer/send-codes", data)
      .then((response) => {
        setBtnDisable(false);
        toast.success("Códigos enviados com sucesso!");
        navigate("/customer/codes");
      })
      .catch((error) => {
        setBtnDisable(false);

        switch (error.response.data.params) {
          case "productId":
            toast.error("Produto não selecionado!");
            break;
          case "invalid-email":
            toast.error("E-mail inserido não é valido ou não existe!");
            break;
          case "amount":
            toast.error("Quantidade não informada!");
            break;
          case "dealerId":
            toast.error("Usúario não informado!");
            break;
          case "user-not":
            toast.error("Usúario não existe!");
            break;
          case "amount-greater":
            toast.error("Quantidade do pedido maior que o estoque!");
            break;
          default:
            toast.error("Erro ao enviar e-mail tente novamente mais tarde!");
            break;
        }
      });
  }

  useEffect(() => {
    setProduct("");
    api
      .get("/products")
      .then((response) => {
        setProducts(response.data.products);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const options = products.map((item: any) => {
    return { label: item.name, value: item.id };
  });

  async function handleExportXlsx() {
    setBtnDisable(true);

    const data = { customerId: user?.id, email, productId: product, amount };

    api
      .post("/customer/export-codes", data)
      .then((response) => {
        const codes = response.data.codes;

        const table = codes.map((row: any) => {
          row.Validade = row.product;
          row.Codigo = row.passwordCode;

          delete row.product;
          delete row.passwordCode;

          return row;
        });

        const workSheet = xlsx.utils.json_to_sheet(table);
        const workBook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(workBook, workSheet, "codigos");
        //Buffer
        xlsx.write(workBook, { bookType: "xlsx", type: "buffer" });
        //Binary string
        xlsx.write(workBook, { bookType: "xlsx", type: "binary" });
        //Download
        xlsx.writeFile(workBook, "Codigos.xlsx");

        setBtnDisable(false);
        toast.success("Códigos gerados com sucesso!");
        navigate("/customer/codes");
      })
      .catch((error) => {
        setBtnDisable(false);

        switch (error.response.data.params) {
          case "productId":
            toast.error("Produto não selecionado!");
            break;
          case "invalid-email":
            toast.error("E-mail inserido não é valido ou não existe!");
            break;
          case "amount":
            toast.error("Quantidade não informada!");
            break;
          case "dealerId":
            toast.error("Usúario não informado!");
            break;
          case "user-not":
            toast.error("Usúario não existe!");
            break;
          case "amount-greater":
            toast.error("Quantidade do pedido maior que o estoque!");
            break;
          default:
            toast.error("Erro ao enviar e-mail tente novamente mais tarde!");
            break;
        }
      });
  }

  return (
    <Container>
      <Template title="Códigos / Enviar">
        <div className="send-codes">
          <h2>Selecionar codigos a serem enviados</h2>
          <form action="" onSubmit={handleSendCodesToEmail}>
            <div className="product-select">
              <Select
                label="Tempo de Validade"
                name="validatetime"
                value={product}
                onChange={(e) => setProduct(e.target.value)}
                options={options}
              />
              <Input
                label="Quantidade"
                type="number"
                required
                value={amount}
                min={0}
                step={1}
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>
            <Input
              label="E-mail"
              type="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="buttons-send-codes">
              <Button
                disabled={btnDisable}
                loading={btnDisable}
                isPrimary
                title="Gerar Planilha"
                type="button"
                onClick={() => handleExportXlsx()}
              />
              <Button
                className="btn-send"
                disabled={btnDisable}
                loading={btnDisable}
                isPrimary
                title="Enviar"
                type="submit"
              />
            </div>
          </form>
        </div>
      </Template>
      <ToastContainer position="top-right" />
    </Container>
  );
}
