import { AllHTMLAttributes, FormEvent, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { api } from "../../services/api";
import { Button } from "../Button";
import { TextArea } from "../TextArea";
import { Container } from "./styles";

interface IAuthCode {
  id: number;
  code: string;
  belongsId: number;
}

interface ModalCustomerRequestCancellationProps
  extends AllHTMLAttributes<HTMLDivElement> {
  authCode?: IAuthCode | null;
  isOpen: boolean;
  onRequestClose: () => void;
}

export const ModalCustomerRequestCancellation = ({
  isOpen,
  onRequestClose,
  authCode,
}: ModalCustomerRequestCancellationProps) => {
  const [description, setDescription] = useState("");
  function handleSendRequestCancellation(event: FormEvent) {
    event.preventDefault();

    const data = { description, customerId: authCode?.belongsId };

    api
      .post(`/customer/cancellation-code/${authCode?.id}`, data)
      .then((response) => {
        toast.success("Pedido de cancelamento de código enviado com sucesso!");
        setDescription("");
        onRequestClose();
      })
      .catch((error) => {
        toast.error(
          "Error ao enviar pedido, confira as informações e tente novamente em alguns instantes!"
        );
      });
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <AiOutlineClose />
      </button>
      <Container>
        <form onSubmit={handleSendRequestCancellation}>
          <h2>Enviar pedido de cancelamento</h2>
          <span>
            Código: <strong>{authCode?.code}</strong>
          </span>
          <TextArea
            label="Motivo"
            maxLength={200}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <div className="content-buttons">
            <Button
              type="button"
              title="Cancelar"
              isPrimary
              onClick={onRequestClose}
            />
            <Button type="submit" title="Enviar" isPrimary />
          </div>
        </form>
      </Container>
    </Modal>
  );
};
