import styled from "styled-components";

export const Container = styled.div`
  .content {
    width: 100%;
    display: flex;
    gap: 1rem;
  }
`;

export const Filter = styled.div`
  margin-top: 1rem;
  background: var(--white);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem;

  .selects {
    display: flex;
    gap: 1rem;
  }

  .periods {
    display: flex;
    gap: 0.25rem;
  }
`;

export const Chip = styled.div`
  background: #e5e5e5;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  cursor: pointer;

  &:hover {
    filter: brightness(0.9);
  }

  &.active {
    background: var(--btv-blue);
    color: #fff;
  }
`;

export const Stats = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;

  @media (max-width: 1450px) {
    .card {
      max-width: 200px;

      span {
        font-size: 0.5rem;
      }
      h2 {
        font-size: 0.8rem;
      }
    }
  }
  .card {
    background: var(--white);
    width: 15%;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;

    svg {
      font-size: 3rem;
    }

    .score {
      text-align: center;
    }

    span {
      font-size: 0.8rem;
    }

    h2 {
      font-size: 3rem;
    }

    &.one {
      background-color: #23c6c8;
      color: #fff;
    }

    &.two {
      background-color: #1ab394;
      color: #fff;
    }

    &.three {
      background-color: #44ab52;
      color: #fff;
    }

    &.four {
      background-color: #30c5e4;
      color: #fff;
    }
  }
`;

export const TopList = styled.div`
  background: var(--white);
  padding: 2rem;
  border-radius: 0.5rem;
  margin-top: 1rem;
  width: 50%;
  max-height: 420px;
  overflow-y: scroll;

  h4 {
    font-size: 1.2rem;
    font-weight: 400;
  }
  table {
    width: 100%;
    border-spacing: 0 0.5rem;
    font-size: 0.8rem;

    th {
      color: #000;
      font-size: 1rem;
      font-weight: bold;
      padding: 0 1rem;
      text-align: left;
      line-height: 1.5rem;
    }

    td {
      padding: 0.25rem 1rem;
      border: 0;
      background: var(--shape);
      color: var(--text-body);
      border-radius: 0.25rem;
      border-bottom: 1px solid #e5e5e5;
    }
  }
`;
