import { AllHTMLAttributes } from "react";
import { AiOutlineClose } from 'react-icons/ai';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { api } from "../../services/api";
import { Button } from '../Button';
import { Container } from "./styles";

interface IProduct {
  name: string
}

interface IAuthCode {
  id: number
  code: string
  product?: IProduct
  belongsId: number
}

interface ModalDeleteCancellationProps extends AllHTMLAttributes<HTMLDivElement> {
  authCode: IAuthCode | null
  isOpen: boolean
  onRequestClose: () => void
}

export const ModalDeleteCancellation = ({ isOpen, onRequestClose, authCode }: ModalDeleteCancellationProps) => {
  function handleDeleteRequest() {
    api.delete(`/cancellations/${authCode?.id}`).then(response => {
      toast.success('Pedido de canecelamento deletado com sucesso!')
      onRequestClose()
    }).catch(error => {
      toast.error('Erro ao deltetar pedido, tente novamente em alguns instantes!')
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <AiOutlineClose />
      </button>
      <Container>
        <h2>Tem certeza que deseja deletar este pedido?</h2>
        <ul>
          <li>
            Produto: <span>{authCode?.product?.name}</span>
          </li>
          <li>
            Código: <span>{authCode?.code}</span>
          </li>
        </ul>

        <div className="content-buttons">
          <Button type='button' title='Cancelar' isPrimary={false} onClick={onRequestClose} />
          <Button type='button' title='Deletar' isPrimary={true} onClick={() => handleDeleteRequest()} />
        </div>
      </Container>
    </Modal>
  )
}