import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { FiDownload } from "react-icons/fi";
import { TbSend } from "react-icons/tb";
import { HiOutlineSearch } from "react-icons/hi";
import { Button } from "../../components/Button";
import { Input } from "../../components/Input";
import { ModalSendDetail } from "../../components/ModalSendDetails";
import { Table } from "../../components/Table";
import { Template } from "../../components/Template";
import { useAuth } from "../../hooks/use-hook";
import { api } from "../../services/api";
import { Container } from "./styles";
import * as xlsx from 'xlsx'
import { toast } from "react-toastify";

export function CodesSend() {
  const [sends, setSents] = useState([])
  const [isFilter, setIsFilter] = useState(false)
  const [email, setEmail] = useState('')
  const [isModalDetail, setIsModalDetail] = useState(false)
  const [sendId, setSendId] = useState(0)

  const { user } = useAuth()

  useEffect(() => {
    handleGetCodesByDealer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleGetCodesByDealer() {
    api.get(`/dealers/codes-send/${user?.id}`, { headers: { email } }).then(response => {
      setSents(response.data.sends)
    })
  }

  async function handleClearFilters() {
    setEmail('')
  }

  function handleOpenModalDetails(id: number) {
    setSendId(id)
    setIsModalDetail(true)
  }

  function handleExportData(data: any) {
    const items = JSON.parse(data)

    const table = items.map((row: any) => {
      row.Validade = row.product;
      row.Codigo = row.passwordCode;

      delete row.product;
      delete row.passwordCode;

      return row
    })

    const workSheet = xlsx.utils.json_to_sheet(table);
    const workBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(workBook, workSheet, 'codigos');
    //Buffer
    xlsx.write(workBook, { bookType: 'xlsx', type: 'buffer' });
    //Binary string
    xlsx.write(workBook, { bookType: 'xlsx', type: 'binary' });
    //Download
    xlsx.writeFile(workBook, 'Codigos.xlsx');
  }

  function handleReSendData(email: string, data: any,) {
    const items = JSON.parse(data)

    api.post('/codes/re-send-codes', {email, data: items}).then((response) => {
      toast.success('Códigos reenviados com sucesso!')
    }).catch((error) => {
      console.log(error);
    })

  }

  return (
    <Container>
      <Template title="Pedidos Enviados">
        <div className="buttons-filter">
          <Button title="Filtrar" isPrimary onClick={() => setIsFilter(!isFilter)} />
        </div>
        {
          isFilter &&
          <div className="filter-content">
            <div className="inputs-content">

              <Input label="E-mail" name="email" type="email" value={email} onChange={e => setEmail(e.target.value)} />
            </div>
            <div className="buttons-content">
              <Button type="button" isPrimary title='Buscar' onClick={() => handleGetCodesByDealer()} />
              <Button type="button" isPrimary title='Limpar Filtros' onClick={() => handleClearFilters()} />
            </div>
          </div>
        }
        <Table title="Pedidos Enviados">
          <thead>
            <tr>
              <th>Data</th>
              <th>Solicitante</th>
              <th>E-mail</th>
              <th>Produto</th>
              <th>Quantidade</th>
              <th>Valor Total</th>
              <th>Opções</th>
            </tr>
          </thead>
          <tbody>
            {
              sends.map((item: any) => {
                return (
                  <tr key={item.id}>
                    <td>{dayjs(item.createdAt).format('DD/MM/YYYY HH:mm')}</td>
                    <td>{item.clientName}</td>
                    <td>{item.emailClient}</td>
                    <td>{item.product?.name ?? '-'}</td>
                    <td>{item.amount ?? '-'}</td>
                    <td>R$ {(item.amount * item.product?.price).toFixed(2) ?? '-'}</td>
                    <td className="buttons-content">
                      <button type="button" className="send-details" onClick={() => handleOpenModalDetails(item.id)} ><HiOutlineSearch /></button>
                      <button type="button" className="send-xlsx" onClick={() => handleExportData(item.data)} ><FiDownload /></button>
                      <button type="button" className="re-send-xlsx" onClick={() => handleReSendData(item.emailClient, item.data)} ><TbSend /></button>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </Table>
      </Template>
      {
        sendId !== 0 &&
        <ModalSendDetail
          isOpen={isModalDetail}
          onRequestClose={() => setIsModalDetail(false)}
          sendCodeId={sendId}
        />
      }
    </Container>
  )
}