import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-areas: "sidebar header" "sidebar main";
  grid-template-columns: auto 1fr;
  grid-template-rows: 5rem 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  main {
    height: 85vh;
    grid-area: main;
    position: relative;
    padding: 1rem;
    overflow-x: hidden;
  }

  header {
    margin-bottom: 1rem;
    color: var(--gray-600);
  }
`