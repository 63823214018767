import styled from "styled-components";

export const Container = styled.div`
  .buttons-filter {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        background: #3C9CDB;
        padding-left: 1rem;
        padding-right: 1rem;
        height: 2.6rem;
        font-size: 1rem;
        min-width: 8rem;
      }
  }

  .filter-content{
    margin-top: 1rem;
    padding: 1rem;
    background: var(--white);
    border-radius: 1rem;
    widows: 100%;

    .inputs-content {
      display: flex;
      gap: 1rem;
      width: 33%;
    }

    .buttons-content {
      display: flex;
      gap: 1rem;
      width: 15%;

      button {
        height: 2.5rem;
        width: 100%;
        font-size: 1rem;
        background: var(--green-700);

        &:last-child {
          background: var(--gray-600);
        }
      }
    }
  }

  .change-buttons {
    display: flex;
    gap: 0.5rem;

    button {
      background: var(--green-700);
      border: none;
      padding: 0.25rem;
      color: var(--white);
      border-radius: 5px;
      transition: all 0.2s;
      &:last-child {
        background: var(--fire)
      }

      &:hover {
        transform: scale(1.1);
      }
    }
  }
`
