import { AllHTMLAttributes } from 'react';
import Modal from 'react-modal';
import { Container } from './styles';

interface ModalChangeRequestProps extends AllHTMLAttributes<HTMLDivElement> {
  isOpen: boolean
  onRequestClose: () => void
}

export const ModalChangeRequest = ({ isOpen, onRequestClose, children }: ModalChangeRequestProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <Container>
        {children}
      </Container>
    </Modal>
  )
}