import React, { InputHTMLAttributes, useEffect, useState } from "react"
import { IconBaseProps } from 'react-icons'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { Container } from "./styles"

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string
  type?: string
  isRequired?: string
  icon?: React.ComponentType<IconBaseProps>;
}

export const Input = ({
  label,
  type,
  icon: Icon,
  ...rest
}: InputProps) => {
  const [isVisible, setIsVisible] = useState(false)
  const [isType, setIstype] = useState('')

  function handlePasswordVisible() {
    setIsVisible(!isVisible)
  }

  useEffect(() => {
    if (isVisible) {
      setIstype('text')
    } else {
      setIstype('password')
    }
  }, [isVisible])

  return (
    <Container id='input'>
      <label htmlFor={label}>{label}</label>
      <div className="content">
        {Icon && <Icon />}
        <input
          type={type === 'password' ? isType : type}
          placeholder={label}
          {...rest}
        />
        {type === 'password' &&
          <button type="button" onClick={() => handlePasswordVisible()}>
            {isVisible ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
          </button>}
      </div>
    </Container>
  )
}