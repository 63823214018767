import { useEffect, useState } from "react"
import { Table } from "../../components/Table"
import { Template } from "../../components/Template"
import { useAuth } from "../../hooks/use-hook"
import { api } from "../../services/api"
import { Container } from "./styles"

export function SellerInventory() {
  const [inventory, setInventory] = useState([])
  const { user } = useAuth()

  useEffect(() => {
    api.get(`/sellers/inventory/${user?.id}`).then(response => {
      setInventory(response.data.inventory)
    }).catch(error => {
      console.log(error)
    })
  }, [inventory, user])

  return (
    <Container>
      <Template title="Estoque">
        <Table title="Seu Estoque">
          <thead>
            <tr>
              <th>Produto</th>
              <th>Quantidade</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              inventory.map((item: any) => {
                return (
                  <tr key={item.productId}>
                    <td>{item.product}</td>
                    <td>{item.amount}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </Table>
      </Template>
    </Container>
  )
}