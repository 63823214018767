import { SelectHTMLAttributes } from 'react';
import { Container } from "./styles";

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  label: string;
  name: string;
  options: Array<{
    value: any;
    label: any;
  }>;
}

export const Select = ({
  label,
  name,
  options,
  ...rest
}: SelectProps) => {
  return (
    <Container id='select'>
      <label htmlFor={name}>{label}</label>
      <select value="" id={name} {...rest}>
        <option value="" disabled hidden>Selecione uma opção</option>
        {
          options.map(option => {
            return <option key={option.value} value={option.value} >{option.label}</option>
          })
        }
      </select>
    </Container>
  )
}