import { FormEvent, useContext, useEffect, useState } from "react"
import { AiOutlineClose } from 'react-icons/ai'
import Modal from 'react-modal'
import { toast } from 'react-toastify'
import { AuthContext } from "../../contexts/AuthContext"
import { api } from "../../services/api"
import { Button } from "../Button"
import { Input } from "../Input"
import { Select } from "../Select"
import { Container } from "./styles"

interface RequestCodesModalProps {
  isOpen: boolean
  onRequestClose: () => void
}

export const RequestCodesModal = ({ isOpen, onRequestClose }: RequestCodesModalProps) => {
  const [products, setProducts] = useState([])
  const [product, setProduct] = useState('')
  const [btnDisable, setBtnDisable] = useState(false)
  const [amount, setAmount] = useState(0)
  const [isConfirmation, setIsConfirmation] = useState(false)
  const [productName, setProductName] = useState('')
  const [productPrice, setProductPrice] = useState(0)
  const [totalPrice, setTotalPrice] = useState(0)
  const { user } = useContext(AuthContext)

  useEffect(() => {
    setProduct('')
    setAmount(0)
    setIsConfirmation(false)
    api.get('/products/').then(response => {
      setProducts(response.data.products)
    }).catch(error => {
      console.log(error)
    })
  }, [])

  useEffect(() => {
    const productId = parseInt(product)
    const result: any = products.find((item: any) => item.id === productId)

    setProductName(result?.name)
    setProductPrice(result?.price)
  }, [products, product, productPrice, productName])

  const options = products.map((item: any) => {
    return ({ label: item.name, value: item.id })
  })

  function handleConfirmation() {
    if (product) {
      if (amount > 0) {
        setTotalPrice(amount * productPrice)
        setIsConfirmation(true)
      } else {
        toast.error('Quantidade obrigatorio')
      }
    } else {
      toast.error('Selecione o produto para continuar!')
    }
  }

  function handleRequestodes(event: FormEvent) {
    event.preventDefault()
    setBtnDisable(true)

    const data = { amount, productId: product, sellerId: user?.id }
    api.post('/requests/create', data).then(response => {
      setBtnDisable(false)
      toast.success('Pedido realizado com sucesso!')
      setProduct('')
      setAmount(0)
      setIsConfirmation(false)
      onRequestClose()
    }).catch(error => {
      setBtnDisable(false)
      toast.error('Erro ao cadastrar pedido, tente novamente em alguns instantes...')
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close">
        <AiOutlineClose />
      </button>

      <Container onSubmit={handleRequestodes}>
        {
          !isConfirmation &&
          <div className="form-content">
            <h2>Fazer Pedidos de Códigos</h2>
            <Select
              label="Tempo de Validade"
              name="validatetime"
              value={product}
              onChange={e => setProduct(e.target.value)}
              options={options}
            />
            <Input label="Quantidade" name="amount" type="number" min={1} value={amount} onChange={e => setAmount(parseInt(e.target.value))} />
            <Button type="button" title="Confirmar" isPrimary onClick={() => handleConfirmation()} />
          </div>
        }
        {
          isConfirmation &&
          <div className="calc-content">
            <h2>Confirme os dados do seu pedido</h2>
            <ul>
              <li><span>Produto:</span> {productName}</li>
              <li><span>Quantidade:</span> {amount}</li>
              <li><span>Valor Total:</span> R$ {totalPrice.toFixed(2)}</li>
            </ul>
            <div className="buttons-content">
              <Button type="button" title="Cancelar" isPrimary onClick={() => setIsConfirmation(false)} />
              <Button type="submit" title="Salvar" isPrimary disabled={btnDisable} loading={btnDisable} />
            </div>
          </div>
        }
      </Container>
    </Modal>
  )
}