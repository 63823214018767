import { AllHTMLAttributes } from "react";
import { AiOutlineClose } from 'react-icons/ai';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { api } from "../../services/api";
import { Button } from '../Button';
import { Container } from "./styles";

interface IDealer {
  id: number
  name: string
  email: string
}

interface ModalDeleteDealerProps extends AllHTMLAttributes<HTMLDivElement> {
  dealer?: IDealer | null
  isOpen: boolean
  onRequestClose: () => void
}

export const ModalDeleteDealer = ({ isOpen, onRequestClose, dealer }: ModalDeleteDealerProps) => {

  function handleDeleteDealer() {
    api.delete(`/sellers/delete-dealers/${dealer?.id}`).then(response => {
      toast.success('Usuário deletado com sucesso!')
      onRequestClose()
    }).catch(error => {
      toast.error('Erro ao deltetar usuário, tente novamente em alguns instantes!')
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <AiOutlineClose />
      </button>
      <Container>
        <h2>Tem certeza que deseja deletar este usuário?</h2>
        <ul>
          <li>
            Nome: <span>{dealer?.name}</span>
          </li>
          <li>
            E-mail: <span>{dealer?.email}</span>
          </li>
        </ul>

        <div className="content-buttons">
          <Button type='button' title='Cancelar' isPrimary={false} onClick={onRequestClose} />
          <Button type='button' title='Deletar' isPrimary={true} onClick={() => handleDeleteDealer()} />
        </div>
      </Container>
    </Modal>
  )
}