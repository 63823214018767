import dayjs from "dayjs";
import { AllHTMLAttributes } from "react";
import { AiOutlineClose } from "react-icons/ai";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/use-hook";
import { api } from "../../services/api";
import { Button } from "../Button";
import { Container } from "./styles";

interface IRquest {
  id: number;
  createdAt: Date;
  productName: string;
  amount: number;
  price: string;
}

interface ModalCustomerDeleteRequestProps
  extends AllHTMLAttributes<HTMLDivElement> {
  request?: IRquest | null;
  isOpen: boolean;
  onRequestClose: () => void;
}

export const ModalCustomerDeleteRequest = ({
  isOpen,
  onRequestClose,
  request,
}: ModalCustomerDeleteRequestProps) => {
  const { user } = useAuth();

  function handleDeleteRequest() {
    api
      .post(`/customer/delete-request/${request?.id}`, { customerId: user?.id })
      .then((response) => {
        toast.success("Pedido deletado com sucesso!");
        onRequestClose();
      })
      .catch((error) => {
        toast.error(
          "Erro ao deltetar pedido, tente novamente em alguns instantes!"
        );
      });
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <AiOutlineClose />
      </button>
      <Container>
        <h2>Tem certeza que deseja deletar este pedido?</h2>
        <ul>
          <li>
            Pedido em:{" "}
            <span>{dayjs(request?.createdAt).format("DD/MM/YYYY HH:mm")}</span>
          </li>
          <li>
            Produto: <span>{request?.productName}</span>
          </li>
          <li>
            Quantidade: <span>{request?.amount}</span>
          </li>
          <li>
            Valor: <span>R$ {parseFloat(request?.price!).toFixed(2)}</span>
          </li>
        </ul>

        <div className="content-buttons">
          <Button
            type="button"
            title="Cancelar"
            isPrimary={false}
            onClick={onRequestClose}
          />
          <Button
            type="button"
            title="Deletar"
            isPrimary={true}
            onClick={() => handleDeleteRequest()}
          />
        </div>
      </Container>
    </Modal>
  );
};
