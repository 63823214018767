import { useEffect, useState } from "react"
import { FaTrash } from "react-icons/fa"
import { ToastContainer } from "react-toastify"
import { Button } from "../../components/Button"
import { Input } from "../../components/Input"
import { ModalAccess } from "../../components/ModalAccess"
import { ModalDeleteDealer } from "../../components/ModalDeleteDealer"
import { Table } from "../../components/Table"
import { Template } from "../../components/Template"
import { useAuth } from "../../hooks/use-hook"
import { api } from '../../services/api'

import { Container } from "./styles"

interface IDealer {
  id: number
  name: string
  email: string
}

export function Dealers() {
  const { user } = useAuth()
  const [isShowFitlers, setIsShowFilters] = useState(false)
  const [isShowModalAccess, setIsShowModalAccess] = useState(false)
  const [name, setName] = useState('')
  const [dealers, setDealers] = useState([])
  const [isShowModalDelte, setIsShowModalDelete] = useState(false)
  const [dealer, setDealer] = useState<IDealer | null>(null)

  function handleShowDelte(dealer: IDealer) {
    setIsShowModalDelete(true)
    setDealer(dealer)
  }

  useEffect(() => {
    api.get(`/sellers/dealers/${user?.id}`, {
      headers: { name }
    }).then((response) => {
      setDealers(response.data.dealers)
    })
  }, [name, user])

  return (
    <Container>
      <Template title="Revendedores">
        <div className="buttons-content">
          <Button title="Filtrar" isPrimary onClick={() => { setIsShowFilters(!isShowFitlers) }} />
          <Button title="Convidar Revendedor" isPrimary onClick={() => { setIsShowModalAccess(!isShowModalAccess) }} />
        </div>
        {
          isShowFitlers &&
          <div className="filter-content">
            <div className="filters-inputs">
              <Input label="Nome" value={name} onChange={(e) => setName(e.target.value)} />
            </div>
            <Button isPrimary={false} title='Limpar Filtro' onClick={() => setName('')} />
          </div>
        }

        <Table title="Revendedores Cadastrados" >
          <thead>
            <tr>
              <th>Nome</th>
              <th>E-mail</th>
              <th>Telefone</th>
              <th>Opções</th>
            </tr>
          </thead>
          <tbody>
            {
              dealers.map((item: any) => {
                return (
                  <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{item.email}</td>
                    <td>{item.phone}</td>
                    <td>< FaTrash className="icon-delete" onClick={() => handleShowDelte(item)} /></td>
                  </tr>
                )
              })
            }
          </tbody>
        </Table>
      </Template>
      <ModalAccess
        isOpen={isShowModalAccess}
        onRequestClose={() => setIsShowModalAccess(false)}
      />
      <ToastContainer position="top-right" />
      <ModalDeleteDealer
        isOpen={isShowModalDelte}
        onRequestClose={() => setIsShowModalDelete(false)}
        dealer={dealer}
      />
    </Container>
  )
}