import styled from "styled-components";

export const Container = styled.form`
display: flex;
flex-direction: column;
  h2 {
    margin-bottom: 2rem;
  }

  button {
    
  }
` 