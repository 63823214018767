import { FormEvent, useEffect, useState } from "react";
import { HiOutlineLockClosed, HiOutlineMail } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Button } from "../../components/Button";
import { Input } from "../../components/Input";
import { useAuth } from "../../hooks/use-hook";
import { Container, Content } from "./styles";

import Logo from "../../assets/images/logo-btv.svg";
import { Loading } from "../../components/Loading";

export function Login() {
  const { signIn, loading, isAuthenticated, user } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("@BTVAPP:token");

    if (!loading && isAuthenticated && token && user) {
      const profile = user?.profileId;

      switch (profile) {
        case 1:
          navigate("/home");
          break;
        case 2:
          navigate("/payments");
          break;
        case 3:
          navigate("/inventory");
          break;
        case 4:
          navigate("/dealer-inventory");
          break;
        case 5:
          navigate("/customer/inventory");
          break;
      }
    }
  }, [isAuthenticated, user, navigate, loading]);

  async function handleLogin(e: FormEvent) {
    e.preventDefault();
    const data = { email, password };
    const user = await signIn(data);

    const profile = user?.profileId;

    switch (profile) {
      case 1:
        navigate("/home");
        break;
      case 2:
        navigate("/payments");
        break;
      case 3:
        navigate("/inventory");
        break;
      case 4:
        navigate("/dealer-inventory");
        break;
      case 5:
        navigate("/customer/inventory");
        break;
    }
  }

  return (
    <Container>
      <Content>
        <section>
          <img src={Logo} alt="BTV APP" />
          <h1>Faça seu login na plataforma</h1>
        </section>
        <form action="login" onSubmit={handleLogin}>
          <Input
            name="email"
            label="E-mail"
            icon={HiOutlineMail}
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            name="password"
            label="Senha"
            icon={HiOutlineLockClosed}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Link to="/forgot">Esqueceu sua senha?</Link>
          <Button
            title="Entrar"
            isPrimary
            loading={loading}
            disabled={loading}
          />
          <span>
            Ainda não e um Revendedor?
            <Link to="/signup">Registre-se</Link>
          </span>
        </form>
      </Content>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {loading && <Loading />}
    </Container>
  );
}
