import jwt_decode from "jwt-decode";
import { createContext, ReactNode, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { api } from "../services/api";

type User = {
  id: number;
  name: string;
  profileId: number;
};

type SignInCredentials = {
  email: string;
  password: string;
}

type AuthContextData = {
  signIn: (credentials: SignInCredentials) => Promise<User>;
  signOut: () => void;
  user: User | null;
  loading: boolean;
  isAuthenticated: boolean;
};

type AuthProviderProps = {
  children: ReactNode;
}

interface JWTPayload {
  id: number
}

export const AuthContext = createContext({} as AuthContextData)

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true)
  const isAuthenticated = !!user;

  useEffect(() => {
    setLoading(true)

    const token = localStorage.getItem('@BTVAPP:token');

    if (token) {
      const { id } = jwt_decode(token) as JWTPayload

      api.get(`/user/${id}`)
        .then(response => {
          const { user } = response.data
          setUser(user)
        })
        .catch(() => {
          signOut()
        })

      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    setLoading(false)
  }, []);

  async function signIn({ email, password }: SignInCredentials) {
    try {
      setLoading(true)

      const response = await api.post('/user/login', {
        email,
        password,
      })

      const { token, refreshToken, user } = response.data;

      localStorage.setItem("@BTVAPP:token", token);
      localStorage.setItem("@BTVAPP:refreshToken", refreshToken);

      setUser(user)

      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      setLoading(false)
      return user
    } catch (error) {
      setLoading(false)
      toast.error('E-mail ou senha invalidos!')
    }
  };

  const signOut = () => {
    setUser(null);
    localStorage.removeItem("@BTVAPP:token");
    localStorage.removeItem("@BTVAPP:refreshToken");
  };

  return (
    <AuthContext.Provider value={{ signIn, signOut, isAuthenticated, user, loading }}>
      {children}
    </AuthContext.Provider>
  )
};