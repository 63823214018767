import styled from "styled-components";

export const Container = styled.div`
  .buttons-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      background: #58AF61;
      padding-left: 1rem;
      padding-right: 1rem;
      height: 2.6rem;
      font-size: 1rem;
      min-width: 8rem;
      margin-left: auto;
    }
  }

  .filter-content {
    margin-top: 1rem;
    background: var(--white);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;

    #input, #select {
      width: calc(100% / 3);
      font-size: 1rem;
    }

    .filters-inputs {
      display: flex;
      gap: 1rem;
    }

    button {
      width: calc(100% / 6);
      height: 2.5rem;
    }
  }

  .table-content {
    margin-top: 1rem;
    background: var(--white);
    border-radius: 0.5rem;
  }
`

