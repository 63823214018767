import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  top: 20%;
  left: 18%;
  background: #EC6833;
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  width: 1rem;
  height: 1rem;
  transition: all 0.2s;
  
  span {
    font-size: 0.8rem;
    padding: 0.2rem;
  }

  &:hover {
    opacity: 0.8;
    cursor: default;
  }
`