import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  
  label {
    display: block;
    font-size: 1rem;
    font-weight: 400;
    color: var(--gray-600);
    margin-bottom: 0.5rem;
  }

  .content {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border: 1px solid none;
    margin-bottom: 0.5rem;
    border-radius: 0.25rem;
    height: 2.6rem;
    background: var(--gray-50);

    svg {
      font-size: 1.8rem;
      color: var(--gray-600);
    }

    input {
      width: 100%; 
      outline: 0;
      border: none;
      padding-left: 5px;
      font-size: 1rem;
      color: var(--gray-800);
      background: var(--gray-50);
    }

    button {
      background: none;
      border: none;
      
      svg {
        font-size: 1.5rem;
        color: var(--btv-blue);
      }
    }

    &:focus-within {
      border: 1px solid var(--btv-blue);

      svg {
        color: var(--btv-blue);
      }
    }
  }
`