import { Container } from "./styles";
import Modal from "react-modal";
import { AiOutlineClose } from "react-icons/ai";
import { useEffect, useState } from "react";
import { api } from "../../services/api";
import { Table } from "../Table";

interface ModalCustomerSendDetailProps {
  sendCodeId: number;
  isOpen: boolean;
  onRequestClose: () => void;
}

export const ModalCustomerSendDetail = ({
  isOpen,
  onRequestClose,
  sendCodeId,
}: ModalCustomerSendDetailProps) => {
  const [sendCode, setSendCode] = useState<any>();
  const [data, setData] = useState([]);

  useEffect(() => {
    api.get(`/customer/send-code-by-id/${sendCodeId}`).then((response) => {
      setSendCode(response.data.sendCodes);

      if (sendCode) {
        const items = JSON.parse(sendCode.data);

        setData(items);
      }
    });
  }, [sendCodeId, sendCode]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <AiOutlineClose />
      </button>
      <Container>
        <Table title={sendCode?.emailClient}>
          <thead>
            <tr>
              <th>Produto</th>
              <th>Côdigo</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item: any, index) => {
              return (
                <tr key={index}>
                  <td>{item.product}</td>
                  <td>{item.passwordCode}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Container>
    </Modal>
  );
};
