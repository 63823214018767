import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { AiOutlineDownload } from "react-icons/ai";
import { MdCheck, MdClose } from "react-icons/md";
import { toast } from "react-toastify";
import { Button } from "../../components/Button";
import { ModalChangePayments } from "../../components/ModalChagePayments";
import { Table } from "../../components/Table";
import { Template } from "../../components/Template";
import { TextArea } from "../../components/TextArea";
import { api } from "../../services/api";
import { Container } from "./styles";

interface IRequest {
  id: number;
  createdAt: Date;
  dealerName: string;
  productName: string;
  amount: number;
  price: string;
  discount: string;
}

export function PaymentsRequest() {
  const [payments, setPayments] = useState([]);
  const [isShowModalChangePayments, setIsShowModalChangePayments] =
    useState(false);
  const [isChangeStatus, setIsChangeStatus] = useState(false);
  const [request, setRequest] = useState<IRequest | null>(null);
  const [description, setDescription] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);

  function handleDownload(file: string) {
    const url = `${process.env.REACT_APP_API}/public/uploads/` + file;
    window.open(url);
  }

  function validateTime(date: Date) {
    const now = dayjs();
    const ago = dayjs(date).hour(+24);
    if (now > ago) {
      return true;
    }
    return false;
  }

  function handleChangeRequest(isChange: boolean, request: IRequest) {
    setIsShowModalChangePayments(true);
    setIsChangeStatus(isChange);
    setRequest(request);
  }

  useEffect(() => {
    api
      .get("/financial/requests")
      .then((response) => {
        setPayments(response.data.requests);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [payments]);

  function handleExecuteChangeApproved() {
    setBtnDisable(true);
    const isChange = true;
    api
      .put(`/financial/change/${request?.id}`, { isChange, description })
      .then((response) => {
        setBtnDisable(false);
        toast.success("Pagamento aprovado com sucesso!");
        setIsShowModalChangePayments(false);
      })
      .catch((error) => {
        setBtnDisable(false);
        toast.error(
          "Confira no seu estoque a quantidade do pedido tem disponibilidade!"
        );
      });
  }

  function handleExecuteChangeDisapproved() {
    setBtnDisable(true);

    const isChange = false;
    api
      .put(`/financial/change/${request?.id}`, { isChange, description })
      .then((response) => {
        toast.success("Pagamento reprovado com sucesso!");
        setIsShowModalChangePayments(false);
        setBtnDisable(false);
      })
      .catch((error) => {
        toast.error("Error ao cancelar item tente novamente mais tarde!");
        setBtnDisable(false);
      });
  }
  return (
    <Container>
      <Template title="Pedidos">
        <Table title="Pagamentos pendentes">
          <thead>
            <tr>
              <th>Arquivo</th>
              <th>Data</th>
              <th>Revendedor</th>
              <th>Produto</th>
              <th>Quantidade</th>
              <th>Total com desconto ($)</th>
              <th>Status</th>
              <th>Opções</th>
            </tr>
          </thead>
          <tbody>
            {payments.map((item: any) => {
              return (
                <tr key={item.id}>
                  <td>
                    <button
                      className="btn-download"
                      onClick={() => handleDownload(item.receipt)}
                    >
                      <AiOutlineDownload />
                    </button>
                  </td>
                  <td>{dayjs(item.updateAt).format("DD/MM/YYYY HH:mm")}</td>
                  <td>{item.dealerName}</td>
                  <td>{item.productName}</td>
                  <td>{item.amount}</td>
                  <td>
                    R${" "}
                    {item.discount
                      ? (
                          parseFloat(item.price) - parseFloat(item.discount)
                        ).toFixed(2)
                      : "-"}
                  </td>
                  <td>
                    <span className={item.status}>{item.status}</span>
                  </td>
                  <td className="change-buttons">
                    <button
                      type="button"
                      onClick={() => handleChangeRequest(true, item)}
                    >
                      <MdCheck />
                    </button>
                    {validateTime(item.updateAt) && (
                      <button
                        type="button"
                        onClick={() => handleChangeRequest(false, item)}
                      >
                        <MdClose />
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Template>
      <ModalChangePayments
        isOpen={isShowModalChangePayments}
        onRequestClose={() => setIsShowModalChangePayments(false)}
      >
        <h2>{isChangeStatus ? "Confirmar Recebimento" : "Negar Pedido"}</h2>
        <span>Confira as informações antes de prosseguir</span>
        <ul>
          <li>
            Data:{" "}
            <strong>
              {dayjs(request?.createdAt).format("DD/MM/YYYY HH:mm")}
            </strong>
          </li>
          <li>
            Vendedor: <strong>{request?.dealerName}</strong>
          </li>
          <li>
            Produto: <strong>{request?.productName}</strong>
          </li>
          <li>
            Quantidade: <strong>{request?.amount}</strong>
          </li>
          <li>
            {" "}
            Total ($):{" "}
            <strong>
              R${" "}
              {(
                parseFloat(request?.price!) - parseFloat(request?.discount!)
              ).toFixed(2)}
            </strong>
          </li>
        </ul>

        <TextArea
          label="Descrição *"
          name="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <div className="modal-buttons">
          <Button
            type="button"
            isPrimary
            title="Cancelar"
            onClick={() => setIsShowModalChangePayments(false)}
          />
          {isChangeStatus ? (
            <Button
              disabled={btnDisable}
              loading={btnDisable}
              type="button"
              isPrimary
              title="Aprovar"
              id="btn-approved"
              onClick={() => handleExecuteChangeApproved()}
            />
          ) : (
            <Button
              loading={btnDisable}
              disabled={btnDisable}
              type="button"
              isPrimary
              title="Reprovar"
              id="btn-disapproved"
              onClick={() => handleExecuteChangeDisapproved()}
            />
          )}
        </div>
      </ModalChangePayments>
    </Container>
  );
}
