import React, { ButtonHTMLAttributes } from 'react';
import { IconBaseProps } from 'react-icons';
import { Container } from './styles';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  title: string
  icon?: React.ComponentType<IconBaseProps>
  isPrimary: boolean
  color?: string
  loading?: boolean
}

export const Button = ({
  title,
  icon: Icon,
  isPrimary,
  loading,
  ...rest
}: ButtonProps) => {
  return (
    <Container className={isPrimary ? 'btn-priamry' : 'btn-secondary'} {...rest} >
      {
        loading && 'Loading . . .'
      }
      {
        !loading &&
        <>
          {Icon && <Icon />}
          {title}
        </>
      }
    </Container>
  )
}