import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { MdCheck, MdClose } from "react-icons/md"
import { toast, ToastContainer } from "react-toastify"
import { Button } from "../../components/Button"
import { Input } from "../../components/Input"
import { ModalChangeRequest } from "../../components/ModalChageRequest"
import { ModalSendCodes } from "../../components/ModalSendCodes"
import { Select } from "../../components/Select"
import { Table } from "../../components/Table"
import { Template } from "../../components/Template"
import { useAuth } from "../../hooks/use-hook"
import { api } from "../../services/api"
import { Container } from "./styles"
import Swal from 'sweetalert2'
import { ToggleSwitch } from "../../components/ToggleSwitch"

interface IRequest {
  id: number
  createdAt: Date
  dealerName: string
  productName: string
  amount: number
  price: string
}

export function DealerRequest() {
  const { user } = useAuth()
  const [requests, setRequests] = useState([])
  const [request, setRequest] = useState<IRequest | null>(null)
  const [status, setStatus] = useState('')
  const [isShowModalChangeRequest, setIsShowModalChangeRequest] = useState(false)
  const [isChangeStatus, setIsChangeStatus] = useState(false)
  const [isShowModalSendCodes, setIsShowModalSendCodes] = useState(false)
  const [btnDisable, setBtnDisable] = useState(false)
  const [discount, setDiscount] = useState('')
  const [resetPrice, setResetPrice] = useState(false)

  useEffect(() => {
    api.get(`/sellers/dealers-requests/${user?.id}`, {
      headers: {
        status
      }
    }).then((response) => {
      setRequests(response.data.requests)
    }).catch(error => {
      console.log(error)
    })

  }, [user, request, status, isShowModalChangeRequest, isShowModalSendCodes])

  function handleChangeRequest(isChange: boolean, request: IRequest) {
    setIsShowModalChangeRequest(true)
    setIsChangeStatus(isChange)
    setRequest(request)
  }

  function handleExecuteChangeApproved() {
    setBtnDisable(true)

    const isChange = true
    const data = { isChange, userId: user?.id, discount, resetPrice }
    api.post(`/sellers/change-requests-dealers/${request?.id}`, data).then((response => {
      toast.success('Pedido aceito com sucesso!')
      setIsShowModalChangeRequest(false)
      setBtnDisable(false)

    })).catch(error => {
      setBtnDisable(false)
      toast.error('Confira no seu estoque a quantidade do pedido tem disponibilidade!')
    })
  }

  function handleExecuteChangeDisapproved() {
    setBtnDisable(true)

    const isChange = false

    const data = { isChange, userId: user?.id }

    api.post(`/sellers/change-requests-dealers/${request?.id}`, data).then((response => {
      toast.success('Pedido reprovado com sucesso!')
      setIsShowModalChangeRequest(false)
      setBtnDisable(false)

    })).catch(error => {
      setBtnDisable(false)
      toast.error('Error ao cancelar item tente novamente mais tarde!')
    })
  }

  function handleSendCodes(request: IRequest) {
    setIsShowModalSendCodes(true)
    setRequest(request)
  }

  function handleReverseOrder(requestId: number) {
    Swal.fire({
      title: 'Cancelar Pedido?',
      text: "Confira se realmente deseja cancelar este pedido!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#049938',
      cancelButtonColor: '#c33a3a',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        api.post(`/sellers/reverse-order/${requestId}`, { userId: user?.id }).then((response) => {
          Swal.fire(
            'Pedido Cancelado!',
            'Seus códigos ja estão em seu inventario.',
            'success'
          )
        }).catch(() => {
          Swal.fire(
            'Erro ao processar a operação!',
            'Confira os dados e tente novamente.',
            'error'
          )
        })
      }
    })
  }

  return (
    <Container>
      <Template title="Pedidos">
        <div className="filter-content">
          <Select
            label="Status"
            name="status"
            value={status}
            onChange={e => setStatus(e.target.value)}
            options={[
              { label: "", value: "" },
              { label: "Aprovado", value: "aprovado" },
              { label: "Aceito", value: "aceito" },
              { label: "Pendente", value: "pendente" },
              { label: "Negado", value: "negado" },
              { label: "Aguardando", value: "aguardando" },
              { label: "Finalizado", value: "finalizado" }
            ]}
          />
        </div>
        <Table title="Pedidos de Revendedores" >
          <thead>
            <tr>
              <th>Data</th>
              <th>Solicitante</th>
              <th>Produto</th>
              <th>Quantidade</th>
              <th>Valor ($)</th>
              <th>Desconto ($)</th>
              <th>Total ($)</th>
              <th>Status</th>
              <th>Opções</th>
            </tr>
          </thead>
          <tbody>
            {
              requests.map((item: any) => {
                return (
                  <tr key={item.id}>
                    <td>{dayjs(item.createdAt).format('DD/MM/YYYY HH:mm')}</td>
                    <td>{item.dealerName}</td>
                    <td>{item.productName}</td>
                    <td>{item.amount}</td>
                    <td>R$ {parseFloat(item.price).toFixed(2)}</td>
                    <td>R$ {item.discount ? parseFloat(item.discount).toFixed(2) : '-'}</td>
                    <td>R$ {item.discount ? (parseFloat(item.price) - parseFloat(item.discount)).toFixed(2) : '-'}</td>
                    <td>
                      <span
                        className={item.status}
                      >
                        {item.status}
                      </span>
                    </td>
                    {
                      item.status === 'pendente' &&
                      <td className="change-buttons">
                        <button type="button" onClick={() => handleChangeRequest(true, item)} ><MdCheck /></button>
                        <button type="button" onClick={() => handleChangeRequest(false, item)} ><MdClose /></button>
                      </td>
                    }
                    {
                      item.status === 'aprovado' &&
                      <td className="send-codes">
                        <button type="button" onClick={() => handleSendCodes(item)} ><MdCheck /></button>
                      </td>
                    }
                    {
                      item.status === 'aceito' &&
                      <td className="cancel-request">
                        <button type="button" onClick={() => handleReverseOrder(item.id)} ><MdClose /></button>
                      </td>
                    }
                  </tr>
                )
              })
            }
          </tbody>
        </Table>
      </Template>
      <ToastContainer position="top-right" />
      <ModalChangeRequest
        isOpen={isShowModalChangeRequest}
        onRequestClose={() => setIsShowModalChangeRequest(false)}
      >
        {
          isChangeStatus ? <h2>Aprovar Pedido</h2> : <h2>Reprovar Pedido</h2>
        }
        <span>Confira as informações antes de prosseguir</span>

        <ul>
          <li>
            Data: <strong>{dayjs(request?.createdAt).format('DD/MM/YYYY HH:mm')}</strong>
          </li>
          <li>
            Vendedor: <strong>{request?.dealerName}</strong>
          </li>
          <li>
            Produto: <strong>{request?.productName}</strong>
          </li>
          <li>
            Quantidade: <strong>{request?.amount}</strong>
          </li>
          <li> Total ($): <strong>R$ {!resetPrice ? parseFloat(request?.price!).toFixed(2) : '0,00'}</strong></li>
          {
            !resetPrice &&
            <>
              <li>
                <div className="discount-form">
                  <Input
                    label="Desconto"
                    name="discount"
                    placeholder="R$ 0,00"
                    type="number"
                    step="0.01"
                    min="0"
                    value={discount}
                    onChange={e => setDiscount(e.target.value)}
                  />
                </div>
              </li>
              <li> Total com desconto ($): <strong>R$ {discount ? (parseFloat(request?.price!) - parseFloat(discount)).toFixed(2) : parseFloat(request?.price!).toFixed(2)}</strong></li>
            </>
          }
          {
            isChangeStatus &&
            <div>
              <ToggleSwitch title="Zerar Preço da venda?" onCLick={() => setResetPrice(!resetPrice)} value={resetPrice} />
            </div>
          }
        </ul>


        <div className="modal-buttons">
          <Button type="button" isPrimary title="Cancelar" onClick={() => setIsShowModalChangeRequest(false)} />
          {
            isChangeStatus ?
              <Button disabled={btnDisable} loading={btnDisable} type="button" isPrimary title="Aprovar" id="btn-approved" onClick={() => handleExecuteChangeApproved()} /> :
              <Button disabled={btnDisable} loading={btnDisable} type="button" isPrimary title="Reprovar" id="btn-disapproved" onClick={() => handleExecuteChangeDisapproved()} />
          }
        </div>

      </ModalChangeRequest>
      <ModalSendCodes
        isOpen={isShowModalSendCodes}
        onRequestClose={() => setIsShowModalSendCodes(false)}
        request={request}
      />
    </Container>
  )
}