import { Container } from "./styles"
import Switch from "react-switch";

interface ToggleSwitchProps {
  title?: string
  onCLick: () => void,
  value: boolean
}

export const ToggleSwitch = ({ onCLick, title, value }: ToggleSwitchProps) => {
  return (
    <Container>
      <label>{title}</label>
      <Switch onChange={onCLick} checked={value} />
    </Container>
  )
}