import styled from "styled-components";

export const Container = styled.div`

`

export const Content = styled.div`
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;
  margin-top: 1rem ;
  min-height: 200px;
  background: var(--white);
  padding: 2rem;
  border-radius: 0.5rem;

  h2 {
    margin-bottom: 2rem;
  }

  .data-cadastral {
    h3 {
      margin-bottom: 1rem;
    }

    table {
      width: 100%;

        span: {
          font-size: 1.2rem;
          color: var(--gray-600);
        }

        input {
          width: 50%;
          color: var(--gray-400);
          height: 2rem;
          font-size: 1.2rem;
          padding: 0.25rem;
          outline: 0;
          border-radius: 5px;
          border: 1px solid var(--gray-400);

          &:focus {
            color: var(--gray-600);
            font-weight: bold;
            border: 2px solid var(--gray-600);
          }
        }
    }
  }

  .buttons-content {
    width: 100%;
    display: flex;

    button {
      margin-left: auto;
      background: var(--green-700);
      height: 2.5rem;
      padding: 0 1rem;
    }
  }
`