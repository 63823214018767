import { FormEvent, useEffect, useState } from "react"
import { toast, ToastContainer } from "react-toastify"
import { Button } from "../../components/Button"
import { Header } from "../../components/Header"
import { Input } from "../../components/Input"
import { useAuth } from "../../hooks/use-hook"
import { api } from "../../services/api"
import { Container, Content } from "./styles"

export function Profile() {
  const { user } = useAuth()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [indication, setIndication] = useState('')
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [profile, setProfile] = useState('')
  const [isAdmin, setIsAdmin] = useState(false)
  const [btnDisabled, setBtnDisabled] = useState(false)

  useEffect(() => {
    api.get(`/user/${user?.id}`)
      .then(response => {
        const { user } = response.data

        setName(user.name)
        setEmail(user.email)
        setPhone(user.phone)
        setProfile(user.profile.description)
        setIndication(user.access_key.users[0].name)

        if (user.profile.description === 'admin' || user.profile.description === 'financial') setIsAdmin(true)
      })
      .catch((error) => {

        console.log(error)
      })
  }, [user])

  function handleChangePassword(event: FormEvent) {
    event.preventDefault()
    setBtnDisabled(true)
    const data = { currentPassword, newPassword, confirmNewPassword }

    api.post(`/user/change-password/${user?.id}`, data).then(response => {
      toast.success('Senha alterada com sucesso!')
      setCurrentPassword('')
      setNewPassword('')
      setConfirmNewPassword('')
      setBtnDisabled(false)
    }).catch(error => {
      toast.error('Erro ao alterar password, tente novamente em instantes!')
      setBtnDisabled(false)
    })
  }

  function handleUpdateUser() {
    setBtnDisabled(true)
    const data = { name, email, phone }

    api.put(`/user/${user?.id}`, data).then(response => {
      toast.success('Dados atualizados com sucesso!')
      setBtnDisabled(false)
    }).catch(error => {
      toast.error('Error ao atualizar dados')
      setBtnDisabled(false)
    })
  }
  return (
    <Container>
      <Header onToggle={() => { }} isProfile />
      <Content>
        <h2>Meu Perfil</h2>
        <div className="data-cadastral">
          <h3>Dados de Cadastro</h3>
          <table>
            <tbody>
              <tr>
                <td>
                  <span>Nome:</span>
                </td>
                <td> <input type="text" value={name} onChange={e => setName(e.target.value)} disabled={isAdmin} /></td>
              </tr>
              <tr>
                <td>
                  <span>E-mail:</span>
                </td>
                <td> <input type="text" value={email} onChange={e => setEmail(e.target.value)} disabled={isAdmin} /></td>
              </tr>
              <tr>
                <td>
                  <span>Telefone:</span>
                </td>
                <td> <input type="text" value={phone} onChange={e => setPhone(e.target.value)} disabled={isAdmin} /></td>
              </tr>
              <tr>
                <td>
                  <span>Perfil:</span>
                </td>
                <td> <input type="text" value={profile} disabled /></td>
              </tr>
              {
                indication &&
                <tr>
                  <td>
                    <span>Filiado a:</span>
                  </td>
                  <td> <input type="text" value={indication} disabled /></td>
                </tr>
              }
            </tbody>
          </table>
        </div>

        <div className="buttons-content">
          <Button isPrimary title="Salvar" type="button" disabled={btnDisabled} loading={btnDisabled} onClick={() => handleUpdateUser()} />
        </div>
      </Content>
      <Content>
        <h2>Alterar Senha</h2>

        <form onSubmit={handleChangePassword}>
          <Input
            label="Senha atual"
            type="password"
            name="current-password"
            value={currentPassword}
            onChange={e => setCurrentPassword(e.target.value)} />
          <Input
            label="Nova senha"
            type="password"
            name="new-password"
            value={newPassword}
            onChange={e => setNewPassword(e.target.value)} />
          <Input
            label="Confirme a nova senha"
            type="password"
            name="confirm-new-password"
            value={confirmNewPassword}
            onChange={e => setConfirmNewPassword(e.target.value)} />

          <div className="buttons-content">
            <Button isPrimary title="Salvar" type="submit" disabled={btnDisabled} loading={btnDisabled} />
          </div>
        </form>
      </Content>
      <ToastContainer position="top-right" />
    </Container>
  )
}