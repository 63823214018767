import FadeLoader from "react-spinners/FadeLoader";
import { Container } from "./styles";

interface LoadingProps {
  opacity?: boolean;
}

export const Loading = ({ opacity }: LoadingProps) => {
  return (
    <Container className={opacity ? "opacity" : ""}>
      <FadeLoader color="#82E268" loading />
    </Container>
  );
};
