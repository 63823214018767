import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  margin: 1rem 0;
  .toggle {
  background: var(--gray-100);
  width: 50px;
  height: 20px;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  input {
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  span {
    max-width: 2rem;
    width: 100%;
    height: 100%;
    background: var(--gray-200);
    border-radius: 100%;
    position: absolute;
    left: 0;
    box-shadow: 1px 1px 2px gray;
    cursor: pointer;
    transition: 0.25s;
  }

  input:checked + span {
    left: 50%;
    background: var(--btv-blue);
  }
  }
`