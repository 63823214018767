import styled from "styled-components";

export const Container = styled.div`
  .buttons-content {
    width: 100%;
    display: flex;
    align-items: center;

    button {
      background: #58AF61;
      padding-left: 1rem;
      padding-right: 1rem;
      height: 2.6rem;
      font-size: 1rem;
      min-width: 8rem;
      margin-left: auto;
    }
  }

  .filter-content {
    margin-top: 1rem;
    background: var(--white);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;

    #input, #select {
      width: calc(100% / 3);
      font-size: 1rem;
    }

    .filters-inputs {
      display: flex;
      gap: 1rem;
    }

    button {
      width: calc(100% / 6);
      height: 2.5rem;
    }
  }

  .table-content {
    margin-top: 1rem;
    background: var(--white);
    border-radius: 0.5rem;
  }

  .finalizado {
    border: 2px solid var(--gray-800);
    color: var(--gray-800);
    border-radius: 1rem;
    font-size: 12px;
    padding: 0.25rem 0.5rem;
    cursor: pointer;
  }


  .pendente {
    border: 2px solid var(--gray-600);
    color: var(--gray-600);
    border-radius: 1rem;
    padding: 0.25rem 0.5rem;
    cursor: pointer;
  }

  .aprovado {
    border: 2px solid #54A65C;
    color: #54A65C;
    padding: 0.25rem 0.5rem;
    border-radius: 1rem;
    cursor: pointer;
  }

  .aguardando {
    border: 2px solid orange;
    color: orange;
    padding: 0.25rem 0.5rem;
    border-radius: 1rem;
    cursor: pointer;
  }

  .aceito {
    border: 2px solid blue;
    color: blue;
    padding: 0.25rem 0.5rem;
    border-radius: 1rem;
    cursor: pointer;
  }

  .negado {
    border: 2px solid var(--fire);
    color: var(--fire);
    padding: 0.25rem 0.5rem;
    border-radius: 1rem;
    cursor: pointer;
  }
  .icon-payment {
      background: blue;
      border: none;
      padding: 0.25rem;
      color: var(--white);
      border-radius: 5px;
      transition: all 0.2s;
      font-size: 1.6rem;
      &:hover {
        transform: scale(1.1);
      }
    }

    .change-buttons {
    display: flex;
    gap: 1rem;


    button {
      background: var(--green-700);
      border: none;
      padding: 0.25rem;
      color: var(--white);
      border-radius: 5px;
      transition: all 0.2s;
      &:last-child {
        background: var(--fire)
      }

      &:hover {
        transform: scale(1.1);
      }
    }
  }
`

