import { useEffect, useState } from "react"
import { FaTrash } from "react-icons/fa"
import { ToastContainer } from "react-toastify"
import { Button } from "../../components/Button"
import { ModalDeleteRequest } from "../../components/ModalDeleteRequest"
import { RequestCodesModal } from "../../components/RequestCodesModal"
import { Table } from "../../components/Table"
import { Template } from "../../components/Template"
import { useAuth } from "../../hooks/use-hook"
import { api } from "../../services/api"
import { Container } from "./styles"

interface IRquest {
  id: number
  createdAt: Date
  productName: string
  amount: number
  price: string
}

export function Requests() {
  const { user } = useAuth()
  const [requests, setRequests] = useState([])
  const [isShowModalRequest, setIsShowModalRequest] = useState(false)
  const [isShowModalDelete, setIsShowModalDelete] = useState(false)
  const [request, setRequest] = useState<IRquest | null>(null)

  function handleShowDelete(request: IRquest) {
    setIsShowModalDelete(true)
    setRequest(request)
  }

  useEffect(() => {
    api.get(`/sellers/requests-by-seller/${user?.id}`).then((response) => {
      setRequests(response.data.requests)
    }).catch(error => {
      console.log(error)
    })

  }, [requests, user])

  return (
    <Container>
      <Template title="Pedidos">
        <div className="buttons-content">
          <Button title="Cadastrar Pedidos" isPrimary onClick={() => setIsShowModalRequest(true)} />
        </div>
        <Table title="Pedidos Realizados" >
          <thead>
            <tr>
              <th>Produto</th>
              <th>Quantidade</th>
              <th>Total ($)</th>
              <th>Status</th>
              <th>Opções</th>
            </tr>
          </thead>
          <tbody>
            {
              requests.map((item: any) => {
                return (
                  <tr key={item.id}>
                    <td>{item.productName}</td>
                    <td>{item.amount}</td>
                    <td>R$ {parseFloat(item.price).toFixed(2)}</td>
                    <td>
                      <span
                        className={item.status}
                      >
                        {item.status}
                      </span>
                    </td>
                    {
                      item.status === 'pendente' &&
                      <td>< FaTrash className="icon-delete" onClick={() => handleShowDelete(item)} /></td>
                    }
                  </tr>
                )
              })
            }
          </tbody>
        </Table>
      </Template>
      <ToastContainer position="top-right" />
      <RequestCodesModal
        isOpen={isShowModalRequest}
        onRequestClose={() => setIsShowModalRequest(false)}
      />
      <ModalDeleteRequest
        isOpen={isShowModalDelete}
        onRequestClose={() => setIsShowModalDelete(false)}
        request={request}
      />
    </Container>
  )
}