import { useContext, useEffect, useState } from "react"
import { AuthContext } from "../../contexts/AuthContext"
import { api } from "../../services/api"
import { Container } from "./styles"

export const BadgeCancellation = () => {
  const [amountRequestCancellations, setAmountRequestCancellations] = useState(0)
  const { user } = useContext(AuthContext);

  useEffect(() => {
    api.get(`/cancellations/count-requests`).then(response => {
      setAmountRequestCancellations(response.data.countCancellations)
    }).catch(error => {
      console.log(error)
    })
  }, [amountRequestCancellations, user])

  if (!amountRequestCancellations) {
    return null
  }

  return (
    <Container>
      <span>{amountRequestCancellations}</span>
    </Container>
  )
}