import { FormEvent, useContext, useState } from "react"
import { AiOutlineClose } from 'react-icons/ai'
import Modal from 'react-modal'
import { toast } from 'react-toastify'
import { AuthContext } from "../../contexts/AuthContext"
import { api } from "../../services/api"
import { Button } from "../Button"
import { Input } from "../Input"
import { Container } from "./styles"

interface MoldalAccessProps {
  isOpen: boolean
  onRequestClose: () => void
}

export const ModalAccess = ({
  isOpen,
  onRequestClose
}: MoldalAccessProps) => {
  const { user } = useContext(AuthContext)

  const [email, setEmail] = useState('')
  const [btnDisable, setBtnDisable] = useState(false)

  async function handleSendAccessKey(event: FormEvent) {
    event.preventDefault()
    setBtnDisable(true)
    api.post('/sellers/send-access-key/', { email, sellerId: user?.id }).then((response) => {
      toast.success('Convite enviado com sucesso!')
      setEmail('')
      setBtnDisable(false)
      onRequestClose()

    }).catch((error) => {
      setBtnDisable(false)
      const statusCode = error.response.status
      if (statusCode === 400) {
        toast.error('E-mail invalido tente novamente!')
      }

      if (statusCode === 409) {
        toast.error('Erro ao gerar chave de acesso, tente novamente mais tarde!')
      }


      if (statusCode === 500) {
        toast.error('Erro ao enviar e-mail, tente novamente mais tarde!')
      }
    })


  }
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close">
        <AiOutlineClose />
      </button>

      <Container onSubmit={handleSendAccessKey}>
        <h2>Enviar chave de acesso</h2>
        <Input label="E-mail"
          value={email}
          onChange={e => setEmail(e.target.value)} />
        <Button type="submit" title="Enviar" isPrimary disabled={btnDisable} />
      </Container>
    </Modal>
  )
}