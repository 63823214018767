import { FormEvent, useEffect, useState } from "react"
import { AiOutlineClose } from 'react-icons/ai'
import { HiOutlineLockClosed, HiOutlineMail, HiOutlinePhone, HiOutlineUser } from 'react-icons/hi'
import Modal from 'react-modal'
import { toast } from 'react-toastify'
import { api } from "../../services/api"
import { Button } from "../Button"
import { Input } from "../Input"
import { Container } from "./styles"

interface EditUser {
  name: string
  email: string
  phone: string
  password: string
}

interface MoldalAccessProps {
  isOpen: boolean
  user?: EditUser | null
  userId?: number | null
  onRequestClose: () => void
}

export const AddSellerModal = ({
  isOpen,
  userId,
  user,
  onRequestClose
}: MoldalAccessProps) => {

  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const [btnDisable, setBtnDisable] = useState(false)

  useEffect(() => {
    if (userId && user) {
      setEmail(user.email)
      setName(user.name)
      setPhone(user.phone)
      setPassword(user.password)
    } else {
      setEmail('')
      setName('')
      setPhone('')
      setPassword('')
    }
  }, [user, userId])


  async function handleAddSeller() {
    setBtnDisable(true)
    const data = { name, email, phone, password }
    api.post('/admin/add-seller', data).then((response) => {
      toast.success('Usuário cadastrado com sucesso!')
      setEmail('')
      setName('')
      setPhone('')
      setPassword('')
      setBtnDisable(false)
      onRequestClose()

    }).catch((error) => {
      setBtnDisable(false)
      const statusCode = error.response.status
      const param = error.response.data.param
      if (statusCode === 400) {
        if (param === 'name') {
          toast.error('O Campo nome e obrigratorio!')
        }
        if (param === 'email') {
          toast.error('O Campo E-mail e obrigratorio!')
        }
        if (param === 'phone') {
          toast.error('O Campo telefone e obrigratorio!')
        }
        if (param === 'password') {
          toast.error('O Campo senha e obrigratorio!')
        }
        if (param === 'Invalid Email') {
          toast.error('O E-mail inseirido e invalido!')
        }
      }

      if (statusCode === 409) {
        toast.error('O E-mail inserido já esta sendo usado!')
      }


      if (statusCode === 500) {
        toast.error('Erro ao cadastrar usuário, tente novamente mais tarde!')
      }
    })
  }

  async function handleEditSeller() {
    setBtnDisable(true)
    const data = { name, email, phone, password }
    api.put('/admin/edit-seller', data, {
      headers: {
        id: userId!
      }
    }).then((response) => {
      toast.success('Usuário editado com sucesso!')
      setEmail('')
      setBtnDisable(false)
      onRequestClose()

    }).catch((error) => {
      setBtnDisable(false)
      const statusCode = error.response.status
      const param = error.response.data.param
      if (statusCode === 400) {
        if (param === 'name') {
          toast.error('O Campo nome e obrigratorio!')
        }
        if (param === 'email') {
          toast.error('O Campo E-mail e obrigratorio!')
        }
        if (param === 'phone') {
          toast.error('O Campo telefone e obrigratorio!')
        }
        if (param === 'password') {
          toast.error('O Campo senha e obrigratorio!')
        }
        if (param === 'Invalid Email') {
          toast.error('O E-mail inseirido e invalido!')
        }
      }

      if (statusCode === 409) {
        toast.error('O E-mail inserido já esta sendo usado!')
      }


      if (statusCode === 500) {
        toast.error('Erro ao editar usuário, tente novamente mais tarde!')
        onRequestClose()
      }
    })
  }

  function handleExecute(event: FormEvent) {
    event.preventDefault()
    if (userId) {
      handleEditSeller()
    } else {
      handleAddSeller()
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close">
        <AiOutlineClose />
      </button>

      <Container onSubmit={handleExecute}>
        <h2>Cadastrar Vendedor</h2>

        <Input
          name='name'
          label='Nome'
          icon={HiOutlineUser}
          type='text'
          value={name}
          onChange={e => setName(e.target.value)}
        />

        <Input
          name='email'
          label='E-mail'
          icon={HiOutlineMail}
          type='email'
          value={email}
          onChange={e => setEmail(e.target.value)}
        />

        <Input
          name='phone'
          label='Telefone'
          icon={HiOutlinePhone}
          type='text'
          value={phone}
          onChange={e => setPhone(e.target.value)}
        />

        <Input
          name='password'
          label='Senha'
          icon={HiOutlineLockClosed}
          type='password'
          value={password}
          onChange={e => setPassword(e.target.value)}
        />

        <Button type="submit" title="Salvar" isPrimary disabled={btnDisable} />
      </Container>
    </Modal>
  )
}