import styled from "styled-components";

export const Container = styled.form`
display: flex;
flex-direction: column;
max-height: 90vh;
.img-edit {
  text-align: center;

  img {
    max-width: 200px;
    max-height: 200px;
    width: 100%;
  }
}

  h2 {
    margin-bottom: 1rem;
  }

  button {
  }
` 