import { FormEvent, useEffect, useState } from "react"
import { AiOutlineClose } from 'react-icons/ai'
import Modal from 'react-modal'
import { toast } from 'react-toastify'
import { api } from "../../services/api"
import { Button } from "../Button"
import { Input } from "../Input"
import { Select } from "../Select"
import { Container } from "./styles"

interface ImportCodesModalProps {
  isOpen: boolean
  onRequestClose: () => void
}

export const ImportCodesModal = ({ isOpen, onRequestClose }: ImportCodesModalProps) => {
  const [file, setFile] = useState<any | null>(null)
  const [products, setProducts] = useState([])
  const [product, setProduct] = useState('')
  const [btnDisable, setBtnDisable] = useState(false)

  useEffect(() => {
    setFile(null)
    setProduct('')
    api.get('/products').then(response => {
      setProducts(response.data.products)
    }).catch(error => {
      console.log(error)
    })
  }, [])

  const options = products.map((item: any) => {
    return ({ label: item.name, value: item.id })
  })

  function handleImportCodes(event: FormEvent) {
    event.preventDefault()
    setBtnDisable(true)
    const data = new FormData()
    data.append("file", file)
    data.append("productId", product)

    api.post('/auth-codes/import', data).then(response => {
      setBtnDisable(false)
      toast.success('Dados em processamento, avisaremos quando tiver terminado!')
      setFile(null)
      setProduct('')
      onRequestClose()
    }).catch(error => {
      toast.error('Erro ao enviar arquivo, confira os dados')
      setBtnDisable(false)
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close">
        <AiOutlineClose />
      </button>

      <Container onSubmit={handleImportCodes}>
        <h2>Importar Lista de Códigos</h2>
        <Select
          label="Tempo de Validade"
          name="validatetime"
          value={product}
          onChange={e => setProduct(e.target.value)}
          options={options}
        />
        <Input
          name="image"
          label="Imagem"
          type="file"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          onChange={e => setFile(e.target.files![0])}
        />
        <Button type="submit" title="Enviar" isPrimary disabled={btnDisable} />
      </Container>
    </Modal>
  )
}