import { useEffect, useState } from "react"
import { FaTrash } from "react-icons/fa"
import { ToastContainer } from "react-toastify"
import { Button } from "../../components/Button"
import { Input } from "../../components/Input"
import { ModalCustomerAccess } from "../../components/ModalCustomerAccess"
import { ModalDeleteCustomer } from "../../components/ModalDeleteCustomer"
import { Table } from "../../components/Table"
import { Template } from "../../components/Template"
import { useAuth } from "../../hooks/use-hook"
import { api } from '../../services/api'

import { Container } from "./styles"

interface ICustomer {
  id: number
  name: string
  email: string
}

export function Customers() {
  const { user } = useAuth()
  const [isShowFitlers, setIsShowFilters] = useState(false)
  const [isShowModalAccess, setIsShowModalAccess] = useState(false)
  const [name, setName] = useState('')
  const [customers, setCustomers] = useState([])
  const [isShowModalDelte, setIsShowModalDelete] = useState(false)
  const [customer, setCustomer] = useState<ICustomer | null>(null)

  function handleShowDelte(customer: ICustomer) {
    setIsShowModalDelete(true)
    setCustomer(customer)
  }

  useEffect(() => {
    api.get(`/dealers/customers/${user?.id}`, {
      headers: { name }
    }).then((response) => {
      setCustomers(response.data.customers)
    })
  }, [name, user, customers])

  return (
    <Container>
      <Template title="Clientes">
        <div className="buttons-content">
          <Button title="Filtrar" isPrimary onClick={() => { setIsShowFilters(!isShowFitlers) }} />
          <Button title="Convidar Cliente" isPrimary onClick={() => { setIsShowModalAccess(!isShowModalAccess) }} />
        </div>
        {
          isShowFitlers &&
          <div className="filter-content">
            <div className="filters-inputs">
              <Input label="Nome" value={name} onChange={(e) => setName(e.target.value)} />
            </div>
            <Button isPrimary={false} title='Limpar Filtro' onClick={() => setName('')} />
          </div>
        }

        <Table title="Clientes Cadastrados" >
          <thead>
            <tr>
              <th>Nome</th>
              <th>E-mail</th>
              <th>Telefone</th>
              <th>Opções</th>
            </tr>
          </thead>
          <tbody>
            {
              customers.map((item: any) => {
                return (
                  <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{item.email}</td>
                    <td>{item.phone}</td>
                    <td>< FaTrash className="icon-delete" onClick={() => handleShowDelte(item)} /></td>
                  </tr>
                )
              })
            }
          </tbody>
        </Table>
      </Template>
      <ModalCustomerAccess
        isOpen={isShowModalAccess}
        onRequestClose={() => setIsShowModalAccess(false)}
      />
      <ToastContainer position="top-right" />
      <ModalDeleteCustomer
        isOpen={isShowModalDelte}
        onRequestClose={() => setIsShowModalDelete(false)}
        customer={customer}
      />
    </Container>
  )
}