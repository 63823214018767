import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { MdCheck, MdClose } from "react-icons/md"
import { toast, ToastContainer } from "react-toastify"
import { Button } from "../../components/Button"
import { ModalChangeRequest } from "../../components/ModalChageRequest"
import { Select } from "../../components/Select"
import { Table } from "../../components/Table"
import { Template } from "../../components/Template"
import { ToggleSwitch } from "../../components/ToggleSwitch"
import { api } from "../../services/api"
import { Container } from "./styles"

interface IRequest {
  id: number
  createdAt: Date
  sellerName: string
  productName: string
  amount: number
  price: string
}

export function SalesRequest() {
  const [requests, setRequests] = useState([])
  const [request, setRequest] = useState<IRequest | null>(null)
  const [status, setStatus] = useState('')
  const [isShowModalChangeRequest, setIsShowModalChangeRequest] = useState(false)
  const [isChangeStatus, setIsChangeStatus] = useState(false)
  const [btnDisable, setBtnDisable] = useState(false)
  const [resetPrice, setResetPrice] = useState(false)

  useEffect(() => {
    api.get(`/admin/requests`, {
      headers: {
        status
      }
    }).then((response) => {
      setRequests(response.data.requests)
    }).catch(error => {
      console.log(error)
    })

  }, [requests, status])

  function handleChangeRequest(isChange: boolean, request: IRequest) {
    setIsShowModalChangeRequest(true)
    setIsChangeStatus(isChange)
    setRequest(request)
  }

  function handleExecuteChangeApproved() {
    setBtnDisable(true)

    const isChange = true
    api.post(`/requests/change/${request?.id}`, { isChange, resetPrice }).then((response => {
      toast.success('Pedido aprovado com sucesso!')
      setIsShowModalChangeRequest(false)
      setBtnDisable(false)
    })).catch(error => {
      setBtnDisable(false)
      toast.error('Confira no seu estoque a quantidade do pedido tem disponibilidade!')
    })
  }

  function handleExecuteChangeDisapproved() {
    setBtnDisable(true)

    const isChange = false
    api.post(`/requests/change/${request?.id}`, { isChange }).then((response => {
      toast.success('Pedido reprovado com sucesso!')
      setIsShowModalChangeRequest(false)
      setBtnDisable(false)
    })).catch(error => {
      setBtnDisable(false)
      toast.error('Error ao cancelar item tente novamente mais tarde!')
    })
  }

  return (
    <Container>
      <Template title="Pedidos">
        <div className="filter-content">
          <Select
            label="Status"
            name="status"
            value={status}
            onChange={e => setStatus(e.target.value)}
            options={[
              { label: "", value: "" },
              { label: "Pendente", value: "pendente" },
              { label: "Aprovado", value: "aprovado" },
              { label: "Reprovado", value: "reprovado" }
            ]}
          />
        </div>
        <Table title="Pedidos de Vendedores" >
          <thead>
            <tr>
              <th>Data</th>
              <th>Vendedor</th>
              <th>Produto</th>
              <th>Quantidade</th>
              <th>Total ($)</th>
              <th>Status</th>
              <th>Opções</th>
            </tr>
          </thead>
          <tbody>
            {
              requests.map((item: any) => {
                return (
                  <tr key={item.id}>
                    <td>{dayjs(item.createdAt).format('DD/MM/YYYY HH:mm')}</td>
                    <td>{item.sellerName}</td>
                    <td>{item.productName}</td>
                    <td>{item.amount}</td>
                    <td>R$ {parseFloat(item.price).toFixed(2)}</td>
                    <td>
                      <span className={item.status}>
                        {item.status}
                      </span>
                    </td>
                    {
                      item.status === 'pendente' &&
                      <td className="change-buttons">
                        <button type="button" onClick={() => handleChangeRequest(true, item)} ><MdCheck /></button>
                        <button type="button" onClick={() => handleChangeRequest(false, item)} ><MdClose /></button>
                      </td>
                    }
                  </tr>
                )
              })
            }
          </tbody>
        </Table>
      </Template>
      <ToastContainer position="top-right" />
      <ModalChangeRequest
        isOpen={isShowModalChangeRequest}
        onRequestClose={() => setIsShowModalChangeRequest(false)}
      >
        {
          isChangeStatus ? <h2>Aprovar Pedido</h2> : <h2>Reprovar Pedido</h2>
        }
        <span>Confira as informações antes de prosseguir</span>

        <ul>
          <li>
            Data: <strong>{dayjs(request?.createdAt).format('DD/MM/YYYY HH:mm')}</strong>
          </li>
          <li>
            Vendedor: <strong>{request?.sellerName}</strong>
          </li>
          <li>
            Produto: <strong>{request?.productName}</strong>
          </li>
          <li>
            Quantidade: <strong>{request?.amount}</strong>
          </li>
          <li> Total ($): <strong>R$ {!resetPrice ? parseFloat(request?.price!).toFixed(2) : '0,00'}</strong></li>
        </ul>
        {
          isChangeStatus &&
          <div>
            <ToggleSwitch title="Zerar Preço da venda?" onCLick={() => setResetPrice(!resetPrice)} value={resetPrice} />
          </div>
        }
        <div className="modal-buttons">
          <Button type="button" isPrimary title="Cancelar" onClick={() => setIsShowModalChangeRequest(false)} />
          {
            isChangeStatus ?
              <Button disabled={btnDisable} loading={btnDisable} type="button" isPrimary title="Aprovar" id="btn-approved" onClick={() => handleExecuteChangeApproved()} /> :
              <Button disabled={btnDisable} loading={btnDisable} type="button" isPrimary title="Reprovar" id="btn-disapproved" onClick={() => handleExecuteChangeDisapproved()} />
          }
        </div>
      </ModalChangeRequest>
    </Container>
  )
}