import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  min-height: 100vh;
  .sidebar {
    min-width: 5rem;
    background: var(--white);
    height: 100%;
    box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);

    .side-header {
      position: relative;
      height: 5rem;
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      
      img {
        max-height: 3rem;
        cursor: pointer;
      }
    }
  }


  .side-body {
    width: 100%;

    .side-itens {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .content {
        position: relative;
        cursor: pointer;
        color: var(--gray-400);
        width: 100%;
        display: flex;
        padding: 1.5rem;
        justify-content: left;
        align-items: center;
        gap: 0.5rem;

        &:hover {
          color: var(--btv-blue);
        }

        svg {
          font-size: 1.6rem;
        }
      }

      .active {
        color: var(--gray-800);

        &::before {
          content: '';
          width: 3px;
          height: 3rem;
          background: var(--btv-blue);
          position: absolute;
          left: 0;
          border-radius: 25%;
        }
      }
    }
  }

  .full-screen-logo {
    display: block;
  }

  .mobile-screen-logo {
    display: none;
  }

  @media (max-width: 720px) {
    .sidebar {
      position: fixed;
      left: -5rem;
      max-width: 5rem;
      z-index: 100;

      .side-body {
        .side-itens {
          span {
            display: none;
          }
        }
      }
    }

    .show {
      left: 0;
    }

    .full-screen-logo {
    display: none;
  }

  .mobile-screen-logo {
    display: block;
  }
  }

 
`