import styled from "styled-components"

export const Container = styled.header`
  grid-area: header;
  width: 100%;
  height: 5rem;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  background-color: var(--white);
  z-index: 100;
  transition: .5s;

  .side-toggle {
    font-size: 1.5rem;
    cursor: pointer;
    transition: color 0.2s;
    color: var(--gray-600);

    &:hover {
      color: var(--gray-800);
    }
  }

  .user-content {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 1.2rem;
    color: var(--gray-600);

    svg {
      font-size: 1.5rem;
      cursor: pointer;
      transition: color 0.2s;

      &:hover {
        color: var(--gray-800);
      }
    }
  }

  .logo {
    max-height: 3rem;
  }
`