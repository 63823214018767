import { useEffect, useState } from "react"
import { FaEdit } from "react-icons/fa"
import { ToastContainer } from "react-toastify"
import { AddSellerModal } from "../../components/AddSellerModal"
import { Button } from "../../components/Button"
import { Input } from "../../components/Input"
import { Table } from "../../components/Table"
import { Template } from "../../components/Template"
import { api } from "../../services/api"
import { Container } from "./styles"

interface Seller {
  id: number
  name: string
  email: string
  phone: string
  password: string
}

export function Sellers() {
  const [isShowFitlers, setIsShowFilters] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isShowModalSeller, setIsShowModalSeller] = useState(false)
  const [sellers, setSellers] = useState([])
  const [seller, setSeller] = useState<Seller>({ email: '', id: 0, name: '', password: '', phone: '' })
  const [name, setName] = useState('')

  useEffect(() => {
    api.get('/admin/sellers', {
      headers: {
        name
      }
    }).then((response) => {
      setSellers(response.data.sellers)

    }).catch(error => {
      console.log(error)
    })
  }, [sellers, name])

  function handleModalClose() {
    setIsEdit(false)
    setIsShowModalSeller(false)
  }

  function handleisEdit(user: any) {
    setSeller(user)
    setIsEdit(true)
    setIsShowModalSeller(true)
  }
  return (
    <Container>
      <Template title="Vendedores">
        <div className="buttons-content">
          <Button title="Filtrar" isPrimary onClick={() => { setIsShowFilters(!isShowFitlers) }} />
          <Button title="Cadastrar Vendedor" isPrimary onClick={() => { setIsShowModalSeller(!isShowModalSeller) }} />
        </div>
        {
          isShowFitlers &&
          <div className="filter-content">
            <div className="filters-inputs">
              <Input label="Nome" value={name} onChange={(e) => setName(e.target.value)} />
            </div>
            <Button isPrimary={false} title='Limpar Filtro' onClick={() => setName('')} />
          </div>
        }
        <Table title="Vendedores Cadastrados" >
          <thead>
            <tr>
              <th>Nome</th>
              <th>E-mail</th>
              <th>Telefone</th>
              <th>Opções</th>
            </tr>
          </thead>
          <tbody>
            {
              sellers.map((item: any) => {
                return (
                  <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{item.email}</td>
                    <td>{item.phone}</td>
                    <td>< FaEdit className="icon-edit" onClick={() => handleisEdit(item)} /></td>
                  </tr>
                )
              })
            }
          </tbody>
        </Table>
      </Template>
      <AddSellerModal
        userId={isEdit ? seller.id : null}
        user={isEdit ? seller : null}
        isOpen={isShowModalSeller}
        onRequestClose={handleModalClose}
      />
      <ToastContainer position="top-right" />
    </Container>
  )
}