import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthProvider } from "./contexts/AuthContext";
import RoutesApp from "./routes";
import GlobalStyle from "./styles/global";

function App() {
  return (
    <AuthProvider>
      <RoutesApp />
      <GlobalStyle />
      <ToastContainer position="top-right" />
    </AuthProvider>
  );
}

export default App;
