import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    margin-bottom: 1rem;
  }

  .content-buttons {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    button {
      width: 100%;
      background: var(--gray-600);
      &:last-child {
        background: var(--green-700);
      }
    }
  }

  form {
    display: flex;
    flex-direction: column;

    .contain {
       object-fit: cover;
    }
    span {
      margin-bottom: 1rem;
    }

    p {
      margin-top: 1rem;
      text-align: center;
    }
  }
`