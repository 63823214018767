import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { MdCheck, MdClose } from "react-icons/md"
import { toast, ToastContainer } from "react-toastify"
import { Button } from "../../components/Button"
import { Input } from "../../components/Input"
import { ModalChangeRequestCancellation } from "../../components/ModalChageRequestCancellation"
import { Table } from "../../components/Table"
import { Template } from "../../components/Template"
import { api } from "../../services/api"
import { Container } from "./styles"

interface IRequest {
  id: number
  createdAt: Date
  code: string
  productName: string
  dealerName: number
}

export function CancelRequest() {
  const [isFilter, setIsFilter] = useState(false)
  const [cancellaments, setCancellaments] = useState([])
  const [request, setRequest] = useState<IRequest | null>(null)
  const [code, setCode] = useState('')
  const [isShowModalChangeRequestCancellation, setisShowModalChangeRequestCancellation] = useState(false)
  const [isChangeStatus, setIsChangeStatus] = useState(false)

  function handleChangeCancellation(isChange: boolean, request: IRequest) {
    setisShowModalChangeRequestCancellation(true)
    setIsChangeStatus(isChange)
    setRequest(request)

  }

  function handleClearFilters() {
    setCode('')
  }

  useEffect(() => {
    api.get('/cancellations', {
      headers: {
        code
      }
    }).then(response => {
      setCancellaments(response.data.cancellations)
    }).catch(error => {
      console.log(error)
    })
  }, [cancellaments, code])

  function handleExecuteChangeApproved() {
    const isChange = true
    api.post(`/cancellations/change/${request?.id}`, { isChange }).then((response => {
      toast.success('Pedido aprovado com sucesso!')
      setisShowModalChangeRequestCancellation(false)
    })).catch(error => {
      toast.error('Erro ao processar cancelamento, tente novamente em instantes!')
    })
  }

  function handleExecuteChangeDisapproved() {
    const isChange = false
    api.post(`/cancellations/change/${request?.id}`, { isChange }).then((response => {
      toast.success('Pedido reprovado com sucesso!')
      setisShowModalChangeRequestCancellation(false)
    })).catch(error => {
      toast.error('Erro ao processar cancelamento, tente novamente em instantes!')
    })
  }

  return (
    <Container>
      <Template title="Cancelamentos">
        <div className="buttons-filter">
          <Button title="Filtrar" isPrimary onClick={() => setIsFilter(!isFilter)} />
        </div>
        {
          isFilter &&
          <div className="filter-content">
            <div className="inputs-content">
              <Input label="Código" value={code} onChange={e => setCode(e.target.value)} />
            </div>
            <div className="buttons-content">
              <Button type="button" isPrimary title='Limpar Filtros' onClick={() => handleClearFilters()} />
            </div>
          </div>
        }
        <Table title="Pedidos de Cancelamento">
          <thead>
            <tr>
              <th>Data</th>
              <th>Código</th>
              <th>Produto</th>
              <th>Revendedor</th>
              <th>Opções</th>
            </tr>
          </thead>
          <thead>
            {
              cancellaments.map((item: any) => {
                return (
                  <tr key={item.id}>
                    <td>{dayjs(item.createdAt).format('DD/MM/YYYY HH:mm')}</td>
                    <td>{item.code.code}</td>
                    <td>{item.code.product.name}</td>
                    <td>{item.user.name}</td>
                    <td className="change-buttons">
                      <button type="button" onClick={() => handleChangeCancellation(true,
                        { id: item.id, code: item.code.code, createdAt: item.createdAt, dealerName: item.user.name, productName: item.code.product.name })} >
                        <MdCheck /></button>
                      <button type="button" onClick={() => handleChangeCancellation(false,
                        { id: item.id, code: item.code.code, createdAt: item.createdAt, dealerName: item.user.name, productName: item.code.product.name })} >
                        <MdClose /></button>
                    </td>
                  </tr>
                )
              })
            }
          </thead>
        </Table>
      </Template>
      <ModalChangeRequestCancellation
        isOpen={isShowModalChangeRequestCancellation}
        onRequestClose={() => setisShowModalChangeRequestCancellation(false)}
      >
        {
          isChangeStatus ? <h2>Aprovar Pedido</h2> : <h2>Reprovar Pedido</h2>
        }
        <span>Confira as informações antes de prosseguir</span>

        <ul>
          <li>
            Data: <strong>{dayjs(request?.createdAt).format('DD/MM/YYYY HH:mm')}</strong>
          </li>
          <li>
            Código: <strong>{request?.code}</strong>
          </li>
          <li>
            Produto: <strong>{request?.productName}</strong>
          </li>
          <li>
            Revendedor: <strong>{request?.dealerName}</strong>
          </li>
        </ul>
        <div className="modal-buttons">
          <Button type="button" isPrimary title="Cancelar" onClick={() => setisShowModalChangeRequestCancellation(false)} />
          {
            isChangeStatus ?
              <Button type="button" isPrimary title="Aprovar" id="btn-approved" onClick={() => handleExecuteChangeApproved()} /> :
              <Button type="button" isPrimary title="Reprovar" id="btn-disapproved" onClick={() => handleExecuteChangeDisapproved()} />
          }
        </div>
      </ModalChangeRequestCancellation>
      <ToastContainer position="top-right" />
    </Container>
  )
}