import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Loading } from "../components/Loading";
import { useAuth } from "../hooks/use-hook";
import { CancelRequest } from "../pages/cancel-request";
import { Codes } from "../pages/codes";
import { DealerInventory } from "../pages/dealer-inventory";
import { Dealers } from "../pages/dealers";
import { DealerRequest } from "../pages/dealers-requests";
import { Forgot } from "../pages/forgot";
import { GeneralInventory } from "../pages/general-inventory";
import { Home } from "../pages/home";
import { Login } from "../pages/login";
import { Payments } from "../pages/payments";
import { PaymentsRequest } from "../pages/payments-requests";
import { Products } from "../pages/products";
import { Profile } from "../pages/profile";
import { Requests } from "../pages/requests";
import { RequestToDealer } from "../pages/requests-to-dealers";
import { Reset } from "../pages/reset";
import { SalesRequest } from "../pages/sales-request";
import { SellerInventory } from "../pages/seller-inventory";
import { Sellers } from "../pages/sellers";
import { SendCodes } from "../pages/send-codes";
import { SignUp } from "../pages/signup";
import { CodesSend } from "../pages/codes-sends";
import { SearchCode } from "../pages/search-code";
import { Customers } from "../pages/customers";
import { CustomerInventory } from "../pages/customer-inventory";
import { CustomerCodes } from "../pages/customer-codes";
import { CustomerSendCodes } from "../pages/customer-send-codes";
import { CustomerCodesSend } from "../pages/customer-codes-sends";
import { RequestToCustomer } from "../pages/requests-to-customer";
import { CustomerRequest } from "../pages/customer-request";
import { SellerCodesSend } from "../pages/seller-codes-sends";
import { CustomerByCustomerRequest } from "../pages/customer-by-customer-request";

interface CustomRouteProps {
  children: any;
}

const PrivateRoute = ({ children }: CustomRouteProps) => {
  const { loading, isAuthenticated, user } = useAuth();

  if (loading) {
    return <Loading />;
  }

  const token = localStorage.getItem("@BTVAPP:token");

  if (!loading && !token) {
    window.alert(
      "Sua sessão expirou, faça o login novamente para continuar navegando!"
    );
    return <Navigate to="/" />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  if (user?.profileId === 1) {
    return isAuthenticated ? children : <Navigate to="/home" />;
  }

  if (user?.profileId === 2) {
    return isAuthenticated ? children : <Navigate to="payments" />;
  }

  if (user?.profileId === 3) {
    return isAuthenticated ? children : <Navigate to="inventory" />;
  }

  if (user?.profileId === 4) {
    return isAuthenticated ? children : <Navigate to="dealer-inventory" />;
  }

  if (user?.profileId === 5) {
    return isAuthenticated ? children : <Navigate to="customer/inventory" />;
  }
};

export const RouteApp = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/reset/:token" element={<Reset />} />

        {/* Rotas Privadas */}
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              {" "}
              <Profile />{" "}
            </PrivateRoute>
          }
        />

        {/* Rotas Admin */}
        <Route
          path="/home"
          element={
            <PrivateRoute>
              {" "}
              <Home />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/sales-request"
          element={
            <PrivateRoute>
              {" "}
              <SalesRequest />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/cancelations"
          element={
            <PrivateRoute>
              {" "}
              <CancelRequest />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/sellers"
          element={
            <PrivateRoute>
              {" "}
              <Sellers />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/general-inventory"
          element={
            <PrivateRoute>
              {" "}
              <GeneralInventory />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/products"
          element={
            <PrivateRoute>
              {" "}
              <Products />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/search-code"
          element={
            <PrivateRoute>
              {" "}
              <SearchCode />{" "}
            </PrivateRoute>
          }
        />

        {/* Rotas Financeiro */}
        <Route
          path="/payments"
          element={
            <PrivateRoute>
              {" "}
              <Payments />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/payments-request"
          element={
            <PrivateRoute>
              {" "}
              <PaymentsRequest />{" "}
            </PrivateRoute>
          }
        />

        {/* Rotas Vendedor */}
        <Route
          path="/inventory"
          element={
            <PrivateRoute>
              {" "}
              <SellerInventory />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/dealers"
          element={
            <PrivateRoute>
              {" "}
              <Dealers />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/dealers-requests"
          element={
            <PrivateRoute>
              {" "}
              <DealerRequest />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/requests"
          element={
            <PrivateRoute>
              {" "}
              <Requests />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/seller/codes-sends"
          element={
            <PrivateRoute>
              {" "}
              <SellerCodesSend />{" "}
            </PrivateRoute>
          }
        />

        {/* Rotas Revendedor */}
        <Route
          path="/dealer-inventory"
          element={
            <PrivateRoute>
              {" "}
              <DealerInventory />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/codes"
          element={
            <PrivateRoute>
              {" "}
              <Codes />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/send-codes"
          element={
            <PrivateRoute>
              {" "}
              <SendCodes />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/requests-to-dealers"
          element={
            <PrivateRoute>
              {" "}
              <RequestToDealer />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/codes-sends"
          element={
            <PrivateRoute>
              {" "}
              <CodesSend />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/customers"
          element={
            <PrivateRoute>
              {" "}
              <Customers />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/customers-requests"
          element={
            <PrivateRoute>
              {" "}
              <CustomerRequest />{" "}
            </PrivateRoute>
          }
        />

        {/* Rotas Clientes */}
        <Route
          path="/customer/inventory"
          element={
            <PrivateRoute>
              {" "}
              <CustomerInventory />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/customer/codes"
          element={
            <PrivateRoute>
              {" "}
              <CustomerCodes />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/customer/send-codes"
          element={
            <PrivateRoute>
              {" "}
              <CustomerSendCodes />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/customer/requests"
          element={
            <PrivateRoute>
              {" "}
              <RequestToCustomer />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/customer/codes-sends"
          element={
            <PrivateRoute>
              {" "}
              <CustomerCodesSend />{" "}
            </PrivateRoute>
          }
        />
         <Route
          path="/customer/change-requests"
          element={
            <PrivateRoute>
              {" "}
              <CustomerByCustomerRequest />{" "}
            </PrivateRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default RouteApp;
