import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Container } from "./styles";

interface ITopSalesChartProps {
  data: ITopListProps[] | [];
}

interface ITopListProps {
  name: string;
  total: number;
  profile: string;
}

export const TopSalesChart = ({
  data,
}: ITopSalesChartProps) => {
  const [categories, setCategories] = useState<string[]>([]);
  const [series, setSeries] = useState<number[]>([]);

  useEffect(() => {
    const results = data?.map((item) => {
      return item.total;
    });

    if (results) {
      setSeries(results);
    }

    const names = data?.map((item) => {
      return item.name;
    });

    if (names) {
      setCategories(names);
    }
  }, [data]);




  const options = {
    chart: {
      id: "basic-bar",
    },
    xaxis: {
      categories: categories,
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: "",
          enabled: false,
        },
      },
    }
  };

  const seriesx = [
    {
      name: "Total Vendido",
      data: series,
    },
  ];

  if (data.length === 0) {
    return <></>;
  }

  return (
    <Container>
      <h2>Total por Vendedores</h2>
      <Chart
        options={options}
        series={seriesx}
        type="bar"
        width="100%"
        height="300"
      />
    </Container>
  );
};
