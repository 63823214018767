import { useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa";
import { ToastContainer } from "react-toastify";
import { Button } from "../../components/Button";
import { ModalCustomerDeleteRequest } from "../../components/ModalCustomerDeleteRequest";
import { ModalCutomerRequest } from "../../components/ModalCustomerRequest";
import { Table } from "../../components/Table";
import { Template } from "../../components/Template";
import { useAuth } from "../../hooks/use-hook";
import { api } from "../../services/api";
import { Container } from "./styles";

interface IRequest {
  id: number;
  createdAt: Date;
  productName: string;
  amount: number;
  price: string;
}

export function RequestToCustomer() {
  const { user } = useAuth();
  const [requests, setRequests] = useState([]);
  const [isShowModalRequest, setIsShowModalRequest] = useState(false);
  const [isShowModalDelete, setIsShowModalDelete] = useState(false);
  const [request, setRequest] = useState<IRequest | null>(null);

  function handleShowDelete(request: IRequest) {
    setIsShowModalDelete(true);
    setRequest(request);
  }

  useEffect(() => {
    api
      .post(`/customer/requests/${user?.id}`)
      .then((response) => {
        setRequests(response.data.requests);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [user, requests]);

  return (
    <Container>
      <Template title="Pedidos">
        <div className="buttons-content">
          <Button
            title="Cadastrar Pedido"
            isPrimary
            onClick={() => setIsShowModalRequest(true)}
          />
        </div>
        <Table title="Pedidos Realizados">
          <thead>
            <tr>
              <th>Produto</th>
              <th>Quantidade</th>
              <th>Valor ($)</th>
              <th>Desconto ($)</th>
              <th>Total ($)</th>
              <th>Status</th>
              <th>Opções</th>
            </tr>
          </thead>
          <tbody>
            {requests.map((item: any) => {
              return (
                <tr key={item.id}>
                  <td>{item.productName}</td>
                  <td>{item.amount}</td>
                  <td>R$ {parseFloat(item.price).toFixed(2)}</td>
                  <td>
                    R${" "}
                    {item.discount ? parseFloat(item.discount).toFixed(2) : "-"}
                  </td>
                  <td>
                    R${" "}
                    {item.discount
                      ? (
                          parseFloat(item.price) - parseFloat(item.discount)
                        ).toFixed(2)
                      : "-"}
                  </td>
                  <td>
                    <span className={item.status}>{item.status}</span>
                  </td>
                  {item.status === "pendente" && (
                    <td>
                      <FaTrash
                        className="icon-delete"
                        onClick={() => handleShowDelete(item)}
                      />
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Template>
      <ToastContainer position="top-right" />
      <ModalCutomerRequest
        isOpen={isShowModalRequest}
        onRequestClose={() => setIsShowModalRequest(false)}
      />
      <ModalCustomerDeleteRequest
        isOpen={isShowModalDelete}
        onRequestClose={() => setIsShowModalDelete(false)}
        request={request}
      />
    </Container>
  );
}
