import { TextareaHTMLAttributes } from "react"
import { Container } from "./styles"

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string
  type?: string
  isRequired?: string
}

export const TextArea = ({
  label,
  type,
  ...rest
}: TextAreaProps) => {

  return (
    <Container id='input'>
      <label htmlFor={label}>{label}</label>
      <div className="content">
        <textarea
          placeholder="Digite seu texto aqui..."
          {...rest}
        />
      </div>
    </Container>
  )
}