import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  :root {
    --dark: #202020;
    --white: #fff; 
    --fire: #CB4747;

    --btv-blue: #69DED9;
    --btv-green: #79E73A;
    
    --green-700: #54A65C;

    --gray-50: #F6F6F6;
    --gray-100: #E5E5E5;
    --gray-200: #BDBDBD;
    --gray-400: #969696;
    --gray-600: #6F6F6F;
    --gray-800: #484848;
  }

  body, input, textarea, select, button {
    font: 400 1rem "Lato", sans-serif;
  }

  @media(max-width: 1080px) {
    html {
        font-size: 90%;
    }
  }

  body {
    background: var(--gray-50);
    color: var(--dark);
  }
  
  @media(max-width: 720px) {
    html {
        font-size: 85%;
    }
  }
  

  button {
    cursor: pointer;
  }
  
  a {
    color: inherit;
    text-decoration: none;
}

  .btn-priamry {
    background: var(--btv-blue);
    color: var(--white);
    font-size: 1.3rem;
    transition: filter 0.2s;
    padding: auto 1rem;
    
    &:hover {
      filter: brightness(0.95);
    }

    &:disabled {
      background: var(--gray-200);
      cursor: not-allowed;
    }
  }

  .btn-secondary {
    background: none;
    border: 2px solid var(--btv-blue);
    color: var(--btv-blue);
    font-size: 1.3rem;
    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.95);
    }

    &:disabled {
      color: var(--gray-200);
      border: 2px solid var(--gray-200);
      cursor: not-allowed;
    }
  }

  .react-modal-overlay {
		background: rgba(0, 0, 0, 0.5);
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
    z-index: 200;
	}
	.react-modal-content {
    background: var(--white);
		width: 100%;
		max-width: 576px;
		padding: 3rem;
		position: relative;
		border-radius: 0.24rem;
	}
	.react-modal-close {
		position: absolute;
		right: 1.5rem;
		top: 1.5rem;
		border: 0;
		background: transparent;
		transition: filter 0.2s;
		&:hover {
			filter: brightness(0.8);
		}
	}
`