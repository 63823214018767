import dayjs from 'dayjs';
import { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { api } from '../../services/api';
import { Button } from '../Button';
import { Container } from './styles';

interface IRequest {
  id: number
  createdAt: Date
  dealerName: string
  productName: string
  amount: number
  price: string
}


interface ModalChangeRequestProps {
  request?: IRequest | null
  isOpen: boolean
  onRequestClose: () => void
}

export const ModalSendCodes = ({ isOpen, onRequestClose, request }: ModalChangeRequestProps) => {
  const [btnDisable, setBtnDisable] = useState(false)

  function handleSendCodes() {
    setBtnDisable(true)

    api.post(`/sellers/send-codes/${request?.id}`).then(response => {
      setBtnDisable(false)

      toast.success('Códigos enviados com sucesso!')
      onRequestClose()
    }).catch(error => {
      setBtnDisable(false)

      toast.error('Erro ao enviar códigos!')
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <AiOutlineClose />
      </button>
      <Container>
        <h2>Enviar códigos</h2>
        <span>Confira as informações antes de prosseguir</span>

        <ul>
          <li>
            Data: <strong>{dayjs(request?.createdAt).format('DD/MM/YYYY HH:mm')}</strong>
          </li>
          <li>
            Vendedor: <strong>{request?.dealerName}</strong>
          </li>
          <li>
            Produto: <strong>{request?.productName}</strong>
          </li>
          <li>
            Quantidade: <strong>{request?.amount}</strong>
          </li>
          <li> Total ($): <strong>R$ {parseFloat(request?.price!).toFixed(2)}</strong></li>
        </ul>

        <div className="modal-buttons">
          <Button type="button" isPrimary title="Cancelar" onClick={() => onRequestClose()} />

          <Button disabled={btnDisable} loading={btnDisable} type="button" isPrimary title="Enviar" id="btn-approved" onClick={() => handleSendCodes()} /> :
        </div>
      </Container>
    </Modal>
  )
}