const translate = (plaintext: string): string => {
  const translation: any = {
    admin: "Admin",
    seller: "Vendedor",
    dealer: "Revendedor",
    customer: "Cliente Revenda",
    financial: "Financeiro",
  };

  return translation[plaintext] || plaintext;
};

export { translate };
