import { FormEvent, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "../../components/Button";
import { Input } from "../../components/Input";
import { api } from "../../services/api";
import { Container } from "./styles";

export function Reset() {
  const [password, setPassword] = useState('')
  const [confirmPassowrd, setConfirmPassword] = useState('')
  const { token } = useParams()
  const navigate = useNavigate()

  function handleResetPassword(event: FormEvent) {
    event.preventDefault()

    const data = { password, confirmPassowrd, token }

    api.post('/user/reset-password/', data).then(response => {
      toast.success('Senha altera com sucesso, Faça login para continuar!')
      navigate('/')
      setPassword('')
      setConfirmPassword('')
    }).catch(error => {
      toast.error('Confira as senhas e tente novamente!')
    })
  }
  return (
    <Container>
      <div className="forgot-content">
        <h2>Resetar senha</h2>
        <form onSubmit={handleResetPassword}>
          <Input label="Nova Senha" type="password" name="new-password"
            value={password} onChange={e => setPassword(e.target.value)} />
          <Input label="Confirmar Senha" type="password" name="confirm-password"
            value={confirmPassowrd} onChange={e => setConfirmPassword(e.target.value)} />
          <Button title="Salvar" isPrimary type="submit" />
          <Link to="/" >Cancelar</Link>
        </form>
      </div>
    </Container>
  )
}