import styled from "styled-components";

export const Container = styled.div`
.buttons-filter {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      background: #3C9CDB;
      padding-left: 1rem;
      padding-right: 1rem;
      height: 2.6rem;
      font-size: 1rem;
      min-width: 8rem;
    }
  }

  .filter-content{
    margin-top: 0.5rem;
    padding: 1rem;
    background: var(--white);
    border-radius: 1rem;

    .inputs-content {
      display: flex;
      gap: 1rem;
    }

    .buttons-content {
      display: flex;
      gap: 1rem;
      width: 25%;
      

      button {
        height: 2.5rem;
        width: 100%;
        background: var(--green-700);
        font-size: 1rem;

        &:last-child {
          background: var(--gray-600);
        }
      }
    }
  }

  .buttons-content {
    display: flex;
    gap: 1rem;

    .send-details{
    background: #3C9CDB;
    border: none;
    padding: 0.25rem;
    color: var(--white);
    border-radius: 5px; 
    transition: all 0.2s;
  }

    .send-xlsx{
    background: var(--green-700);
    border: none;
    padding: 0.25rem;
    color: var(--white);
    border-radius: 5px; 
    transition: all 0.2s;
  }
  .re-send-xlsx{
    background: #F77500;
    border: none;
    padding: 0.25rem;
    color: var(--white);
    border-radius: 5px; 
    transition: all 0.2s;
  }
  }
  
`