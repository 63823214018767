import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    margin-bottom: 1rem;
  }

  button {
  }

  .content-buttons {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    button {
      width: 100%;
  
      &:last-child {
        background: var(--fire);
      }
    }
  }


  .modal-buttons {
    width: 100%;
    display: flex;
    gap: 1rem;

    button {
      width: 100%;
      background: var(--gray-400);
    }

    #btn-approved {
      background: var(--green-700);
    }

    #btn-disapproved {
      background: var(--fire);
    }
  }

  ul {
    list-style: none;
    margin-top: 2rem;
  }
`
