import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { Table } from "../../components/Table"
import { Template } from "../../components/Template"
import { api } from "../../services/api"
import { Container } from "./styles"

export function Payments() {
  const [payments, setPayments] = useState([])

  useEffect(() => {
    api.get('/financial/payments').then(response => {
      setPayments(response.data.payments)
    }).catch(error => {
      console.log(error)
    })
  }, [payments])

  return (
    <Container>
      <Template
        title="Pagamentos"
      >
        <Table title="Pagamentos concluidos">
          <thead>
            <tr>
              <th>Data</th>
              <th>Descrição</th>
              <th>Produto</th>
              <th>Quantidade</th>
              <th>Total ($)</th>
            </tr>
          </thead>
          <tbody>
            {
              payments.map((item: any) => {
                return (
                  <tr key={item.id}>
                    <td>{dayjs(item.createdAt).format('DD/MM/YYYY HH:mm')}</td>
                    <td>{item.description}</td>
                    <td>{item.request?.productName}</td>
                    <td>{item.request?.amount}</td>
                    <td>R$ {parseFloat(item.request?.price).toFixed(2)}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </Table>
      </Template>
    </Container>
  )
}