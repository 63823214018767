import { useEffect, useState } from "react";
import { IconBaseProps } from "react-icons";
import { BiCard, BiMailSend, BiSearch } from "react-icons/bi";
import { FaBoxes, FaRegAddressCard, FaUsers } from "react-icons/fa";
import {
  MdOutlineCancel,
  MdOutlineDashboard,
  MdOutlineRequestQuote,
  MdOutlineSell,
  MdPointOfSale,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { BadgeCancellation } from "../BadgeCancellation";
import { BadgePapyments } from "../BadgePayments";
import { BadRequests } from "../BadgeRequests";
import { Container } from "./styles";

import Logo from "../../assets/images/logo-btv.svg";
import LogoIcon from "../../assets/images/menu-icon.svg";
import { useAuth } from "../../hooks/use-hook";

interface SidebarProps {
  toggle: boolean;
}
interface RoutesProps {
  slug: string;
  icon: IconBaseProps;
  title: string;
  badge?: boolean;
  type?: string;
}

const adminRoutes: RoutesProps[] = [
  {
    slug: "/home",
    icon: <MdOutlineDashboard />,
    title: "Dashboard",
  },
  {
    slug: "/sales-request",
    icon: <MdOutlineRequestQuote />,
    title: "Pedidos",
    badge: true,
    type: "requests",
  },
  {
    slug: "/cancelations",
    icon: <MdOutlineCancel />,
    title: "Cancelamentos",
    badge: true,
    type: "cancel",
  },
  {
    slug: "/sellers",
    icon: <FaRegAddressCard />,
    title: "Vendedores",
  },
  {
    slug: "/general-inventory",
    icon: <FaBoxes />,
    title: "Estoque Geral",
  },
  {
    slug: "/products",
    icon: <MdOutlineSell />,
    title: "Produtos",
  },
  {
    slug: "/search-code",
    icon: <BiSearch />,
    title: "Buscar Código",
  },
];

const financialRoutes: RoutesProps[] = [
  {
    slug: "/payments",
    icon: <MdPointOfSale />,
    title: "Pagamentos",
  },
  {
    slug: "/payments-request",
    icon: <MdOutlineRequestQuote />,
    title: "Pedidos",
    badge: true,
    type: "payments",
  },
];

const sellerRoutes: RoutesProps[] = [
  {
    slug: "/inventory",
    icon: <FaBoxes />,
    title: "Estoque",
  },
  {
    slug: "/seller/codes-sends",
    icon: <BiMailSend />,
    title: "Pedidos Enviados",
  },
  {
    slug: "/dealers",
    icon: <FaRegAddressCard />,
    title: "Revendedores",
  },
  {
    slug: "/dealers-requests",
    icon: <MdOutlineRequestQuote />,
    title: "Fluxo de Vendas",
    badge: true,
    type: "requests",
  },
  {
    slug: "/requests",
    icon: <MdOutlineSell />,
    title: "Solicitação de Códigos",
  },
];

const dealerRoutes: RoutesProps[] = [
  {
    slug: "/dealer-inventory",
    icon: <FaBoxes />,
    title: "Estoque",
  },
  {
    slug: "/codes",
    icon: <BiCard />,
    title: "Meus Códigos",
  },
  {
    slug: "/requests-to-dealers",
    icon: <MdOutlineSell />,
    title: "Solicitação de Códigos",
  },
  {
    slug: "/codes-sends",
    icon: <BiMailSend />,
    title: "Pedidos Enviados",
  },
  {
    slug: "/customers",
    icon: <FaUsers />,
    title: "Clientes",
  },
  {
    slug: "/customers-requests",
    icon: <MdOutlineRequestQuote />,
    title: "Pedidos Recebidos",
    badge: true,
    type: "requests",
  },
];

const customerRoutes: RoutesProps[] = [
  {
    slug: "/customer/inventory",
    icon: <FaBoxes />,
    title: "Estoque",
  },
  {
    slug: "/customer/codes",
    icon: <BiCard />,
    title: "Meus Códigos",
  },
  {
    slug: "/customer/requests",
    icon: <MdOutlineSell />,
    title: "Solicitações de Códigos",
  },
  {
    slug: "/customer/codes-sends",
    icon: <BiMailSend />,
    title: "Pedidos Enviados",
  },
  {
    slug: "/customers",
    icon: <FaUsers />,
    title: "Clientes",
  },
  {
    slug: "/customer/change-requests",
    icon: <MdOutlineRequestQuote />,
    title: "Pedidos Recebidos",
    badge: true,
    type: "requests",
  },
];

export const Sidebar = ({ toggle }: SidebarProps) => {
  const { user } = useAuth();
  const [isRole, setIsRole] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.profileId === 1) {
      setIsRole("admin");
    }
    if (user?.profileId === 2) {
      setIsRole("financial");
    }
    if (user?.profileId === 3) {
      setIsRole("seller");
    }
    if (user?.profileId === 4) {
      setIsRole("dealer");
    }
    if (user?.profileId === 5) {
      setIsRole("customer");
    }
  }, [user]);

  return (
    <Container>
      <div className={toggle ? "sidebar" : "sidebar show"}>
        <div className="side-header">
          <div className="logo-content" onClick={() => navigate("/")}>
            {toggle ? (
              <img className="full-screen-logo" src={LogoIcon} alt="BTV" />
            ) : (
              <img className="full-screen-logo" src={Logo} alt="BTV" />
            )}
            <img className="mobile-screen-logo" src={LogoIcon} alt="BTV" />
          </div>
        </div>
        <div className="side-body">
          <div className="side-itens">
            {isRole === "admin" &&
              adminRoutes.map((itens: RoutesProps) => {
                return (
                  <div
                    className="content"
                    onClick={() => navigate(itens.slug)}
                    key={itens.slug}
                  >
                    <>
                      {itens.badge && itens.type === "requests" && (
                        <BadRequests />
                      )}
                      {itens.badge && itens.type === "cancel" && (
                        <BadgeCancellation />
                      )}
                      {itens.icon}
                      {!toggle && <span>{itens.title}</span>}
                    </>
                  </div>
                );
              })}
            {isRole === "financial" &&
              financialRoutes.map((itens: RoutesProps) => {
                return (
                  <div
                    className="content"
                    onClick={() => navigate(itens.slug)}
                    key={itens.slug}
                  >
                    <>
                      {itens.badge && itens.type === "payments" && (
                        <BadgePapyments />
                      )}
                      {itens.badge && itens.type === "cancel" && (
                        <BadgeCancellation />
                      )}
                      {itens.icon}
                      {!toggle && <span>{itens.title}</span>}
                    </>
                  </div>
                );
              })}
            {isRole === "seller" &&
              sellerRoutes.map((itens: RoutesProps) => {
                return (
                  <div
                    className="content"
                    onClick={() => navigate(itens.slug)}
                    key={itens.slug}
                  >
                    <>
                      {itens.badge && <BadRequests />}
                      {itens.icon}
                      {!toggle && <span>{itens.title}</span>}
                    </>
                  </div>
                );
              })}
            {isRole === "dealer" &&
              dealerRoutes.map((itens: RoutesProps) => {
                return (
                  <div
                    className="content"
                    onClick={() => navigate(itens.slug)}
                    key={itens.slug}
                  >
                    <>
                      {itens.badge && <BadRequests />}
                      {itens.icon}
                      {!toggle && <span>{itens.title}</span>}
                    </>
                  </div>
                );
              })}
            {isRole === "customer" &&
              customerRoutes.map((itens: RoutesProps) => {
                return (
                  <div
                    className="content"
                    onClick={() => navigate(itens.slug)}
                    key={itens.slug}
                  >
                    <>
                      {itens.icon}
                      {!toggle && <span>{itens.title}</span>}
                    </>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </Container>
  );
};
