import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin-top: 1rem;
  padding: 1rem;
  background: var(--white);
  border-radius: 0.5rem;

  h3 {
    font-weight: bold;
		 padding: 1rem 2rem;
  }

  table {
    width: 100%;
		border-spacing: 0 0.5rem;
    margin-top: 1rem;
    font-size: 0.8rem;

    th {
			color: var(--gray-800);
			font-weight: 400;
			padding: 1rem 2rem;
			text-align: left;
			line-height: 1.5rem;
		}

    td {
			padding: 0.5rem 2rem;
			border: 0;
			background: var(--shape);
			color: var(--text-body);
        border-radius: 0.25rem;
      svg {
        font-size: 1.2rem;
        cursor: pointer;
        transition: filter 0.2s;
        
        &:hover{
          filter: brightness(0.9);
        }
      }

      .icon-edit {
        color: blue;
      }

      .icon-delete {
        margin-left: 1rem;
        color: var(--fire);
      }
			&:first-child {
				color: var(--text-title);
			}
			&.deposit {
				color: var(--green);
			}
			&.withdraw {
				color: var(--red);
			}
		}
  }
`