import styled from "styled-components";

export const Container = styled.div`
  .dropdown {
    max-width: 200px;
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    z-index: 200;
  }

  .content {
    min-width: 220px;
    background: var(--white);
    border-radius: 6px;
    padding: 1rem;
    box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35);
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 5rem;
    right: 1rem;
    z-index: 201;
    transition: 0.5s;

    a {
      font-size: 1rem;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;

      svg {
        font-size: 0.9rem;
      }
    }

    span {
      font-size: 1rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: var(--fire);
      cursor: pointer;
      transition: filter 0.2s;

      svg {
        font-size: 0.9rem;
      }

      &:hover {
        filter: brightness(1.2);
      }
    }
  }
`;
