import { TableHTMLAttributes } from "react"
import { Container } from "./styles"

interface TableProps extends TableHTMLAttributes<HTMLTableElement> {
  title: string
}

export const Table = ({
  title,
  children,
  ...rest
}: TableProps) => {
  return (
    <Container>
      <h3>{title}</h3>
      <table {...rest}>
        {children}
      </table>
    </Container>
  )
}