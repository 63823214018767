import { useEffect, useState } from "react"
import { HiOutlineUpload } from "react-icons/hi"
import { ToastContainer } from "react-toastify"
import { Button } from "../../components/Button"
import { ImportCodesModal } from "../../components/ImportCodesModal"
import { Table } from "../../components/Table"
import { Template } from "../../components/Template"
import { api } from "../../services/api"
import { Container } from "./styles"

export function GeneralInventory() {
  const [inventory, setInventory] = useState([])
  const [isShowModalImport, setIsShowModalImport] = useState(false)


  useEffect(() => {
    api.get('/admin/general-inventory').then(response => {
      setInventory(response.data.generalIntentory)
    }).catch(error => {
      console.log(error)
    })
  }, [inventory])

  return (
    <Container>
      <Template title="Estoque Geral">
        <div className="buttons-content">
          <Button icon={HiOutlineUpload} title="Importar Códigos" isPrimary onClick={() => setIsShowModalImport(true)} />
        </div>
        <Table title="Seu estoque">
          <thead>
            <tr>
              <th>Produto</th>
              <th>Quantidade</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              inventory.map((item: any) => {
                return (
                  <tr key={item.productId}>
                    <td>{item.product}</td>
                    <td>{item.amount}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </Table>
      </Template>
      <ImportCodesModal isOpen={isShowModalImport} onRequestClose={() => setIsShowModalImport(false)} />
      <ToastContainer position="top-right" />
    </Container>
  )
}