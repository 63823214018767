import axios, { AxiosError } from "axios";

const url = process.env.REACT_APP_API;

export const api = axios.create({
  baseURL: `${url}/api`,
});

let isRefreshing = false;

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    if (axios.isCancel(error)) {
      return console.log(error);
    }
    
    if (error.response!.status === 401) {
      const refreshToken = localStorage.getItem("@BTVAPP:refreshToken");

      const originalConfig: any = error.config;

      if (!isRefreshing) {
        isRefreshing = true;

        api
          .post("/user/refresh-token", { refreshToken })
          .then((response) => {
            const { token } = response.data;

            localStorage.setItem("@BTVAPP:token", token);

            api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
            originalConfig.headers.common["Authorization"] = `Bearer ${token}`;

            return originalConfig;
          })
          .catch((err) => {
            isRefreshing = false;
            localStorage.removeItem("@BTVAPP:token");
            localStorage.removeItem("@BTVAPP:refreshToken");
            return Promise.reject(err);
          });
      }
      isRefreshing = false;

      return Promise.reject(error);
    }
    isRefreshing = false;
    return Promise.reject(error);
  }
);
