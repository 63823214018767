import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
`

export const Content = styled.div`
  display: flex;
  gap: 1rem;
  width: 70%;

  section {
    padding-right: 5rem;
    text-align: end;
    padding-top: 3rem;
    width: 100%;
    max-width: 600px;

    h1 {
      margin-top: 2rem;
      font-size: 3rem;
    }
    
    img {
      margin-bottom: 1rem;
      width: 100%;
      max-width: 200px;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    background: var(--white);
    border-radius: 5px;
    padding: 2rem;

    a {
      color: var(--btv-blue);
    }

    span {
      margin-top: 1rem;
      display: flex;
      gap: 0.5rem;
    }
  }

  @media (max-width: 720px) {
    flex-direction: column;
    width: 95%;
  }
`