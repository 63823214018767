import { useEffect, useRef, useState } from "react";
import Chart from "react-apexcharts";
import { api } from "../../../services/api";
import { Container } from "./styles";

interface ISalesEvolutionProps {
  period: string;
  userId?: string;
  profileId?: string;
}

export const SalesEvolutionSales = ({
  period,
  userId,
  profileId,
}: ISalesEvolutionProps) => {
  const [categories, setCategories] = useState([]);
  const [series, setSeries] = useState([]);

  const debounceRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    debounceRef.current = setTimeout(() => {
      api.post("/dashboard/show-data-chart", { period, userId, profileId })
        .then((response) => {
          setCategories(response.data.categories);
          setSeries(response.data.series);
        })
        .catch((error) => {
          console.log(error);
        });
    }, 500);

    return () => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
    };
  }, [period, userId, profileId]);

  const options = {
    chart: {
      id: "basic-bar",
    },
    xaxis: {
      categories: categories,
    },
  };

  const seriesx = [
    {
      name: "series-1",
      data: series,
    },
  ];

  return (
    <Container>
      <h2>Evolução de Vendas</h2>
      <Chart
        options={options}
        series={seriesx}
        type="bar"
        width="100%"
        height="300"
      />
    </Container>
  );
};
