import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .forgot-content {
    max-width: 400px;
    width: 100%;
    background: var(--white);
    border-radius: 1rem;
    padding: 2rem;

    h2 {
      text-align: center;
      margin-bottom: 2rem;
    }

    form {
      width: 100%;
      display: flex;
      flex-direction: column;

      a {
        margin-top: 1rem;
        text-align: center;
      }
    }
  }
`