import { Container } from './styles';
import Modal from 'react-modal';
import { AiOutlineClose } from 'react-icons/ai';
import { useEffect, useState } from 'react';
import { api } from '../../services/api';
import { Table } from '../Table';

interface ModalCodeRequestDetailsDetailProps {
  requestId: number
  isOpen: boolean
  onRequestClose: () => void
}

export const ModalCodeRequestDetails = ({ isOpen, onRequestClose, requestId }: ModalCodeRequestDetailsDetailProps) => {
  const [sendCode, setSendCode] = useState([])

  useEffect(() => {
    api.get(`/dealers/codes-request/${requestId}`).then(response => {
      setSendCode(response.data.requestCodes)
    })

    return setSendCode([]);
  }, [requestId])


  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <AiOutlineClose />
      </button>
      <Container>
        <Table title="Códigos enviados">
          <thead>
            <tr>
              <th></th>
              <th>Produto</th>
              <th>Côdigo</th>
            </tr>
          </thead>
          <tbody>
            {
              sendCode.map((item: any, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.productName}</td>
                    <td>{item.passwordCode}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </Table>
      </Container>
    </Modal>
  )
}