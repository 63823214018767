import { AiOutlineClose } from 'react-icons/ai';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { api } from '../../services/api';
import { Button } from '../Button';
import { Container } from './styles';

interface Product {
  id: number
  name: string
}

interface ModalDeleteProductsProps {
  product: Product
  isOpen: boolean
  onRequestClose: () => void
}

export const ModalDeleteProducts = ({ isOpen, onRequestClose, product }: ModalDeleteProductsProps) => {

  function handleDeleteProduct() {
    api.delete(`/products/${product.id}`).then(response => {
      toast.success('Produto deletado com sucesso')
      onRequestClose()
    }).catch(errror => {
      toast.error('Error ao deletar produto!')
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <AiOutlineClose />
      </button>
      <Container>
        <h2>Você tem certeza que deseja excluir este produto ?</h2>
        <span>{product?.name}</span>

        <div className="content-buttons">
          <Button type='button' title='Cancelar' isPrimary={false} onClick={onRequestClose} />
          <Button type='button' title='Deletar' isPrimary={true} onClick={handleDeleteProduct} />
        </div>
      </Container>
    </Modal>
  )
}