import { useEffect, useState } from "react"
import { api } from "../../services/api"
import { Container } from "./styles"

export const BadgePapyments = () => {
  const [amountPayments, setAmountPayments] = useState(0)

  useEffect(() => {
    api.get(`/financial/count-requests/`).then(response => {
      setAmountPayments(response.data.totalRequests)
    }).catch(error => {
      console.log(error)
    })
  }, [amountPayments])

  if (!amountPayments) {
    return null
  }

  return (
    <Container>
      <span>{amountPayments}</span>
    </Container>
  )
}