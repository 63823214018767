import styled from "styled-components";

export const Container = styled.form`
  display: flex;
  flex-direction: column;

  h2 {
    margin-bottom: 2rem;
  }

  button {
    
  }

  .form-content {
    width: 100%;

    button {
      width: 100%;
    }
  }

  .calc-content {
    width: 100%;

    ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      margin-bottom: 10%;
      gap: 1rem;

      li {
        font-size: 1.2rem;

        span {
          color: var(--gray-600);
          margin-right: 5px;
        }
      }
    }

    .buttons-content {
      width: 100%;
      display: flex;
      gap: 1rem;

      button {
        width: 100%;
        background: var(--fire);

        &:last-child {
          background: var(--green-700);
        }
      }
    }
  }
` 