import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { api } from "../../../services/api";
import { Container, Filter } from "./styles";

interface ITransactionsByYearProps {
  initialYear: string;
}

const TransactionsByYear = ({ initialYear }: ITransactionsByYearProps) => {
  const [year, setYear] = useState(initialYear);
  const [categories, setCategories] = useState<string[]>([]);
  const [series, setSeries] = useState([{ name: '', data: [] }]);

  useEffect(() => {
    const handleListTransactions = async () => {
      try {
        const response = await api.get(`/dashboard/list-transactions-by-year`, {
          params: { year }
        });
        const { months, added, sold } = response.data;

        setCategories(months.map((month: any) => `${month}`));
        setSeries([
          { name: 'Adiquiridos', data: added },
          { name: 'Vendidos', data: sold }
        ]);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    handleListTransactions();
  }, [year]);

  const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setYear(e.target.value);
  };

  const options = {
    chart: {
      id: "basic-bar"
    },
    xaxis: {
      categories
    },
    plotOptions: {
      bar: {
        horizontal: false
      }
    },
    dataLabels: {
      enabled: false
    },
    title: {
      text: 'Transações por Mês',
      align: 'center' as const
    }
  };

  return (
    <Container>
      <Filter className="select-year">
        <label htmlFor="year-select">Escolha um ano:</label>
        <select id="year-select" value={year} onChange={handleYearChange}>
          <option value="2024">2024</option>
          <option value="2023">2023</option>
        </select>
      </Filter>
      <Chart
        options={options}
        series={series}
        type="bar"
        width="100%"
        height="300"
      />
    </Container>
  );
};

export { TransactionsByYear };
